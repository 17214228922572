import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import Base from './styles/base';
import Variables from './styles/variables';
import ReactGA from 'react-ga4';

const root = createRoot(document.getElementById('root') as HTMLElement);

ReactGA.initialize('G-1QPT1B798L');

root.render(
  <React.StrictMode>
    <Variables />
    <Base />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
