import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

export const LoaderWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > img {
    margin-bottom: 10px;
  }

  .loader-text {
    color: ${({ theme }) => theme.pages.text.primary};
    margin-bottom: 20px;
  }
`;
