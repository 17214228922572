import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@src/dbSyncManager/tables/user.entity';

interface UserState {
  data: any[];
}

const initialState: UserState = {
  data: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<User[]>) {
      state.data = action.payload;
    },
  },
});

export const { setUsers } = usersSlice.actions;
export default usersSlice.reducer;
