import { db } from '@src/dbSyncManager/init';
import { useLiveQuery } from 'dexie-react-hooks';

export const useFindNameById = (id: number, type: string) => {
  return useLiveQuery(async () => {
    if (type === 'teams') {
      const groupList = await db.groups.toArray();
      return groupList.find((group) => group.id === id)?.name;
    }
    // Add other types based on need (e.g., initiatives, programs, invoice)
    return null;
  }, [id, type]);
};
