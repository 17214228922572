import Avatar from '@mui/material/Avatar';
import { FltTooltip } from '@src/components/base/fltTooltip/fltTooltip';
import { getDisplayName } from '@src/utils/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { CustomAvatarProps } from './avatarInterface';
import { AvatarContainer } from './avatarStyle';
import { AppState } from '@src/app/rootReducer';

/**
 * CustomAvatar is a functional React component that renders a customized avatar.
 *
 * The component fetches the display name using Redux and `getDisplayName` utility function.
 * If `tooltip` is true, it wraps the avatar in a `CustomTooltip` displaying the avatar and the display name.
 * Otherwise, it simply returns the avatar.
 */

const CustomAvatar: React.FC<CustomAvatarProps> = ({
  bgcolor,
  avatarColor,
  fontSize,
  width,
  height,
  marginLeft,
  verticalAlign,
  preferredName,
  firstName,
  lastName,
  avatarInitials,
  style,
  tooltip = false,
  id,
  shape="default"
}) => {
  const displayName = getDisplayName(
    useSelector((state: AppState) => state?.displayName?.displayName),
    id
  );
  const theme = useSelector((state: AppState) => state.theme.theme);
  const avatar = (
    <Avatar
      sx={{
        backgroundColor: bgcolor ? bgcolor : avatarColor ? avatarColor : '',
        color: 'var(--absolute-white)',//'var(--dark-text-primary)',
        fontSize: fontSize ? fontSize : '9px',
        width: width ? width : '20px',
        height: height ? height : '20px',
        marginLeft: marginLeft,
        verticalAlign: verticalAlign,
        borderRadius: shape == 'square' ? "8px" : "",
      }}
      style={style}
      alt={preferredName}
    >
      {avatarInitials
        ? avatarInitials
        : `${preferredName ? preferredName.charAt(0).toUpperCase() : ''}`}
    </Avatar>
  );

  if (tooltip) {
    return (
      <FltTooltip
        title={
          <AvatarContainer>
            {avatar} {displayName}
          </AvatarContainer>
        }
      >
        {avatar}
      </FltTooltip>
    );
  }

  return avatar;
};

export default CustomAvatar;
