import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

export const ProfileMenuWrapper = styled.div<{ theme: Theme }>`
  background-color: ${({ theme }) => theme.fltMenu.background.primary};
  .user-info-panel {
    .user-info-profile {
      .user-pic {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;
      }
      .user-name {
        color: ${({ theme }) => theme.fltMenu.menuItem.text.primary};
        text-align: center;
        margin: 0;
      }
      .email-div {
        color: ${({ theme }) => theme.fltMenu.menuItem.text.secondary};
        text-align: center;
        margin: 0;
      }
    }
    .user-info-panel-unit {
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .logout-div {
        margin-left: 0.5rem;
      }
      button {
        border: none;
        color: ${({ theme }) => theme.fltMenu.menuItem.text.primary};
        padding: 0px 12px;
        background-color: ${({ theme }) => theme.fltMenu.background.primary};
        img {
          width: 28px;
          height: 28px;
          vertical-align: middle;
        }
        i {
          font-size: 16px;
          padding: 0 2px 0 6px;
          vertical-align: middle;
        }
        span {
          white-space: nowrap;
          color: ${({ theme }) => theme.fltMenu.menuItem.text.primary};
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .user-accounts-controls {
      border: 1px solid transparent;
      margin: 0 12px 4px 12px;
      border-radius: 6px;
      .privacy-div {
        text-align: center;
      }
    }
  }

  .action-btn-div {
    padding: 0 20px;
    margin-top: 10px;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-border {
      padding: 4px;
      border: ${({ theme }) => `0.5px solid ${theme.fltMenu.menuItem.text.primary}`};
      border-radius: 50%;
    }
    i {
      font-size: 18px;
    }
  }
  @media (max-width: 576px) {
    .user-info-link {
      p {
        display: none !important;
      }
    }
    .page-container.body-trimmed {
      // width: 100% !important;
      width: calc(100% - 0px) !important;
      margin-left: 0px !important;
    }
    .top-bar {
      display: block !important;
    }
    .sidebar-handle {
      display: none !important;
    }
    .scrollwrap {
      flex: none !important;
    }
  }
`;
