import { useSelector } from 'react-redux';
import { FltButtonProps } from './fltButtonInterface';
import { FltDefaultButton } from './fltButtonStyles';
import React from 'react';
import { AppState } from '@src/app/rootReducer';
const FltButton = ({
  size = 'default',
  rightGlyph,
  leftGlyph,
  type,
  isLoading,
  action,
  disabled,
  children,
  styles,
  onClick,
  appearance = 'filled',
  shape = 'rectangle',
  childLayout = 'default',
}: FltButtonProps) => {
  const theme = useSelector((data: AppState) => data.theme.theme);
  return (
    <FltDefaultButton
      type={action}
      size={size}
      btnType={type}
      appearance={appearance}
      onClick={onClick}
      disabled={disabled}
      styles={styles}
      theme={theme}
      shape={shape}
      childLayout={childLayout}
    >
      {leftGlyph}
      {children && children}
      {rightGlyph}
      {isLoading && <>loading...</>}
    </FltDefaultButton>
  );
};

export default FltButton;
