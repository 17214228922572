import { createGlobalStyle } from 'styled-components';
import '/src/styles/mixins.scss';

const Base = createGlobalStyle`
*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: Inter, sans-serif;
}

*:focus {
  outline: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  -moz-tab-size: 4;
  font-feature-settings: normal;
  -webkit-font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
  word-break: break-word;
  transition: all 0.3s ease-in-out;
}

body {
  /* margin: 0;
  padding: 0; */
  /* font-family: var(--font-inter-ui); */
  /* font-style: normal;
  font-weight: 400;
  color: var(--light-text-primary);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  background-color: var(--absolute-white); */
}

/* body.dark {
  margin: 0;
  padding: 0;
  font-family: var(--font-inter-ui);
  font-style: normal;
  font-weight: 400;
  color: var(--dark-text-primary);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  background-color: var(--dark-bg);
} */

/* button{
  font-family: var(--font-inter-ui);
} */



/* p {
  @include set-text-style(var(--font-inter-ui), 400, normal, var(--text-black));
  @include set-text-size();

  &.size\:xs {
    @include set-text-size(var(--font-size-xs), var(--line-height-xs));
  }

  &.size\:sm {
    @include set-text-size(var(--font-size-sm), var(--line-height-sm));
  }
  
  &.size\:base {
    @include set-text-size(var(--font-size-base), var(--line-height-base));
  }

  &.size\:lg {
    @include set-text-size(var(--font-size-lg), var(--line-height-lg));
  }

  &.size\:xl {
    @include set-text-size(var(--font-size-xl), var(--line-height-xl));
  }

  &.size\:2xl {
    @include set-text-size(var(--font-size-2xl), var(--line-height-2xl));
  }

  &.size\:3xl {
    @include set-text-size(var(--font-size-3xl), var(--line-height-3xl));
  }

  &.size\:4xl {
    @include set-text-size(var(--font-size-4xl), var(--line-height-4xl));
  }

  &.size\:5xl {
    @include set-text-size(var(--font-size-5xl), var(--line-height-5xl));
  }

  &.text\:center {
    text-align: center;
  }

  &.text\:right {
    text-align: right;
  }

  &.text\:left {
    text-align: left;
  }
} */

/* 
a {
    color: var(--primary);
    text-decoration: none;
    background-color: transparent;
    pointer-events: auto !important;
  
    &:hover {
      color: var(--primary-40);
    }
  }
  
  ul,
  li {
    list-style: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @include set-text-style();
    transition: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 10px 0;
    cursor: default !important;
  }
  
  input{
    font-family: var(--font-inter-ui);
    font-weight: normal;
    font-style: normal;
  }
  
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  
  textarea{
    font-family: var(--font-inter-ui);
    font-weight: normal;
    font-style: normal;
  }
  
  textarea {
  
      font-family: var(--font-inter-ui);
      font-weight: normal;
      font-style: normal;
      color: black;
    
  } */

  html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /* font: inherit; */
  vertical-align: baseline;
  font-family: Inter, sans-serif;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

 
`;

export default Base;
