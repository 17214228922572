import { AppProvider } from '@src/providers/app';
import SnackbarProviderWrapper from '@src/providers/snackbarProviderWrapper';
import ReactGA from 'react-ga4';
import './app.scss';
import { LocalStorageProvider } from './context/localStorageContext';
import { AppRoutes } from './routes';

function App() {
  ReactGA.initialize('G-1QPT1B798L');
  return (
    <AppProvider>
      <LocalStorageProvider>
        <SnackbarProviderWrapper>
          <AppRoutes />
        </SnackbarProviderWrapper>
      </LocalStorageProvider>
    </AppProvider>
  );
}

export default App;
