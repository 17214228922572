// Define the colors object with explicit typing
import { Theme } from '@src/styles/themes/themeInterface';
import styled, { css, CSSObject, RuleSet } from 'styled-components';
import { ButtonProps, Shape, Size, Type } from './fltIconButtonInterface';

// Define the sizes object with explicit typing
const sizes: { [key in Size]: RuleSet<object> } = {
  default: css`
    padding: 0px 14px;
    font-size: 13px;
    height: 32px;
    gap: 6px;
    svg {
      font-size: 18px;
    }
  `,
  sm: css`
    padding: 4px;
    font-size: 13px;
    height: 28px;
    width: 28px;
    gap: 4px;
    svg {
      font-size: 16px;
    }
  `,
};

const shapeStyles: { [key in Shape]: RuleSet<object> } = {
  square: css`
    width: 32px;
    border-radius: 6px;
  `,
};

// Define color styles
const iconStyles = {
  primary: css<{ theme: Theme }>`
    background: ${({ theme }) => theme.surface.background.secondary};
    background: transparent;
    color: var(--grey-60);
    border: ${({ theme }) => `1px solid ${theme.border.secondary}`};
    &:hover {
      border-color: ${({ theme }) => theme.border.primary};
      color: var(--grey-90);
    }

    &:active {
      border-color: ${({ theme }) => theme.border.primary};
    }

    &:disabled {
    }
  `,
};

const getButtonStyles = (type: Type) => {
  return iconStyles[type];
};

export const FltButtonWrapper = styled.button<ButtonProps>`
  appearance: none;
  padding: 0px;
  margin: 0px;
  border: 1px solid transparent;
  display: inline-flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  transition: 150ms ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  z-index: 0;
  /* transform: translateY(1px); */
  text-wrap: nowrap;
  ${({ btnType = 'primary' }) => getButtonStyles(btnType)}
  ${({ shape = 'square' }) => shapeStyles[shape]}
  ${({ size = 'default' }) => sizes[size]}

  ${({ btnStatus }) =>
    btnStatus === 'active'
      ? css`
          border-color: ${({ theme }) => theme.border.primary};
        `
      : ``}


  &:focus {
    outline: none;
  }

  .btn-content {
    display: grid;
    grid-auto-flow: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    width: 100%;
    pointer-events: none;
    position: relative;
    z-index: 0;
    color: inherit;
  }
  ${({ styles }) => styles && css(styles as CSSObject)}
`;
