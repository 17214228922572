// Define the colors object with explicit typing
import { Theme } from '@src/styles/themes/themeInterface';
import styled, { css, RuleSet } from 'styled-components';
import { Appearance, ButtonProps, ChildLayout, Shape, Size, Type } from './fltButtonInterface';

const dropdownBtnStyles = css<{ theme: Theme }>`
  display: flex;
  padding: var(--spacing-0x, 0px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: 1px solid ${({ theme }) => theme.fltButton.dropdown.border.primary};
  background: ${({ theme }) => theme.fltButton.dropdown.background.primary};
  &:hover {
    border-color: ${({ theme }) => theme.fltButton.dropdown.border.hover.primary};
    background: ${({ theme }) => theme.fltButton.dropdown.background.hover.primary};
  }

  &:active {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.fltButton.dropdown.border.active.primary};
    background: ${({ theme }) => theme.fltButton.dropdown.background.active.primary};
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.dropdown-open {
    background: ${({ theme }) => theme.fltButton.dropdown.background.opened.primary};
  }
`;

// Define the sizes object with explicit typing
const sizes: { [key in Size]: RuleSet<object> } = {
  default: css`
    padding: 0px 14px;
    font-size: 13px;
    height: 32px;
    gap: 6px;
    svg {
      font-size: 20px;
    }
  `,
  large: css`
    padding: 0px 15px;
    font-size: 15px;
    height: 48px;
    gap: 8px;
    svg {
      font-size: 18px;
    }
  `,
};

const layouts: { [key in ChildLayout]: RuleSet<object> } = {
  default: css`
    justify-content: center;
  `,
  'space-between': css`
    justify-content: space-between;
  `,
};

const shapeStyles: { [key in Shape]: RuleSet<object> } = {
  circle: css`
    border-radius: 50%;
    width: 32px;
    padding: 0;
  `,
  rounded: css`
    border-radius: 20px;
  `,
  rectangle: css`
    border-radius: 8px;
  `,
};

// Define color styles
const filledStyles = {
  primary: css<{ theme: Theme }>`
    background-color: ${({ theme }) => theme.surface.button.primary.default};
    color: ${({ theme }) => theme.content.button.primary.default};
    box-shadow:
      0px 0px 4px 0px rgba(255, 255, 255, 0.12) inset,
      0px 2px 6px 0px rgba(2, 78, 174, 0.25),
      0px 0px 0px 1px var(--Brand-Blue-70, #004091) inset,
      0px 2px 0px 0px var(--Brand-Blue-50, #306fbe) inset;

    &:hover {
      color: ${({ theme }) => theme.content.button.primary.hover};
      box-shadow:
        0px 0px 4px 0px rgba(255, 255, 255, 0.12) inset,
        0px 2px 6px 0px rgba(2, 78, 174, 0.25),
        0px 0px 0px 1px var(--Brand-Blue-60, #024eae) inset,
        0px 2px 0px 0px var(--Brand-Blue-40, #5e90ce) inset;
      background-color: ${({ theme }) => theme.surface.button.primary.hover};
    }

    &:focus {
      background-color: ${({ theme }) => theme.surface.button.primary.default};
      color: ${({ theme }) => theme.content.button.primary.default};
      box-shadow:
        0px 0px 0px 2px rgba(2, 78, 174, 0.2),
        0px 0px 4px 0px rgba(255, 255, 255, 0.12) inset,
        0px 2px 6px 0px rgba(2, 78, 174, 0.25),
        0px 0px 0px 1px var(--Brand-Blue-70, #004091) inset,
        0px 2px 0px 0px var(--Brand-Blue-50, #306fbe) inset;
    }

    &:active {
      color: ${({ theme }) => theme.content.button.primary.active};
      background-color: ${({ theme }) => theme.surface.button.primary.active};
      box-shadow:
        0px 0px 4px 0px rgba(255, 255, 255, 0.12) inset,
        0px 2px 6px 0px rgba(2, 78, 174, 0.25),
        0px 0px 0px 1px var(--Brand-Blue-80, #002655) inset,
        0px 2px 0px 0px var(--Brand-Blue-50, #306fbe) inset;
    }

    &:disabled {
      color: ${({ theme }) => theme.content.button.primary.disabled};
      background-color: ${({ theme }) => theme.surface.button.primary.disabled};
    }
  `,
  secondary: css<{ theme: Theme }>`
    background-color: ${({ theme }) => theme.surface.button.secondary.default};
    color: ${({ theme }) => theme.content.button.secondary.default};
    border: ${({ theme }) => `1px solid ${theme.border.secondary}`};
    box-shadow: 0px 1px 4px 0px rgba(212, 212, 212, 0.16);

    &:hover {
      color: ${({ theme }) => theme.content.button.secondary.active};
      border: ${({ theme }) => `1px solid ${theme.border.primary}`};
      background-color: ${({ theme }) => theme.surface.button.secondary.default};
      box-shadow: 0px 1px 4px 0px rgba(212, 212, 212, 0.16);
    }
    &:focus {
      background-color: ${({ theme }) => theme.surface.button.secondary.default};
      color: ${({ theme }) => theme.content.button.secondary.default};
      border: ${({ theme }) => `1px solid ${theme.border.tertiary}`};
      box-shadow:
        0px 0px 0px 2px rgba(2, 78, 174, 0.2),
        0px 2px 6px 0px rgba(212, 212, 212, 0.25);
    }
    &:active {
      color: ${({ theme }) => theme.content.button.secondary.active};
      border: ${({ theme }) => `1px solid ${theme.border.primary}`};
      background-color: ${({ theme }) => theme.surface.button.secondary.active};
      box-shadow: 0px 1px 4px 0px rgba(212, 212, 212, 0.16);
    }

    &:disabled {
      color: ${({ theme }) => theme.content.button.secondary.disabled};
      background-color: ${({ theme }) => theme.surface.button.secondary.default};
      border: ${({ theme }) => `1px solid ${theme.border.tertiary}`};
      box-shadow: 0px 1px 4px 0px rgba(212, 212, 212, 0.16);
    }
  `,
  tertiary: css<{ theme: Theme }>`
    background-color: ${({ theme }) => theme.surface.button.tertiary.default};
    color: ${({ theme }) => theme.fltButton.text.secondary};
    // border: ${({ theme }) => `1px solid ${theme.border.tertiary}`};
    box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.primary}`};

    &:hover {
      box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.hover.secondary} 0 0 0 1px`};
      background-color: ${({ theme }) => theme.surface.button.tertiary.hover};
    }
    &:active {
      background-color: ${({ theme }) => theme.surface.button.tertiary.active};
    }
    &:focus {
      background-color: ${({ theme }) => theme.surface.button.tertiary.active};
      color: ${({ theme }) => theme.content.button.tertiary.active};
      box-shadow: 0px 0px 0px 2px rgba(2, 78, 174, 0.2);
    }
    &:disabled {
      background-color: ${({ theme }) => theme.surface.button.tertiary.disabled};
    }
  `,
  danger: css<{ theme: Theme }>`
    background-color: ${({ theme }) => theme.fltButton.background.danger};
    color: ${({ theme }) => theme.fltButton.text.primary};
    border: ${({ theme }) => `1px solid ${theme.fltButton.border.danger}`};
    box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.primary} 0 0 0 1px`};

    &:hover {
      box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.hover.danger} 0 0 0 1px`};
      background-color: ${({ theme }) => theme.fltButton.background.hover.danger};
    }
  `,
};

const outlineStyles = {
  primary: css<{ theme: Theme }>`
    background-color: transparent;
    color: ${({ theme }) => theme.fltButton.background.primary};
    border: ${({ theme }) => `1px solid ${theme.fltButton.border.primary}`};
    box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.primary} 0 0 0 1px`};

    &:hover {
      /* background-color: ${({ theme }) => theme.fltButton.background.secondary}; */
      background-color: rgba(27, 52, 184, 0.12);
      color: ${({ theme }) => theme.fltButton.text.primary};
      box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.hover.primary} 0 0 0 1px`};
    }
  `,
  secondary: css<{ theme: Theme }>`
    background-color: transparent;
    color: ${({ theme }) => theme.fltButton.text.secondary};
    border: ${({ theme }) => `1px solid ${theme.fltButton.border.secondary}`};
    box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.primary}`};

    &:hover {
      /* background-color: ${({ theme }) => theme.fltButton.background.hover.secondary}; */
      background-color: rgba(27, 52, 184, 0.12);
      box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.hover.secondary} 0 0 0 1px`};
    }
  `,
  tertiary: css<{ theme: Theme }>``,
  danger: css<{ theme: Theme }>`
    background-color: transparent;
    color: ${({ theme }) => theme.fltButton.background.danger};
    border: ${({ theme }) => `1px solid ${theme.fltButton.border.danger}`};
    box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.primary} 0 0 0 1px`};

    &:hover {
      background-color: ${({ theme }) => theme.fltButton.background.danger};
      color: ${({ theme }) => theme.fltButton.text.primary};
      box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.hover.danger} 0 0 0 1px`};
    }
  `,
};

const noneStyles = {
  primary: css<{ theme: Theme }>`
    background-color: inherit;
    color: ${({ theme }) => theme.fltButton.text.primary};
    border: none;
    box-shadow: none;

    &:hover {
    }
  `,
  secondary: css<{ theme: Theme }>`
    background-color: inherit;
    color: ${({ theme }) => theme.fltButton.text.secondary};
    border: none;
    box-shadow: none;

    &:hover {
    }
  `,
  tertiary: css<{ theme: Theme }>`
    background-color: inherit;
    color: ${({ theme }) => theme.fltButton.text.tertiary};
    border: none;
    box-shadow: none;

    &:hover {
    }
  `,
  danger: css<{ theme: Theme }>`
    background-color: inherit;
    color: ${({ theme }) => theme.fltButton.text.primary};
    border: none;
    box-shadow: none;

    &:hover {
    }
  `,
};

const getButtonStyles = (type: Type, appearance: Appearance, btnFor: string) => {
  if (btnFor === 'dropdown-btn') {
    return dropdownBtnStyles;
  }

  if (appearance === 'outline') {
    return outlineStyles[type];
  } else if (appearance === 'none') {
    return noneStyles[type];
  }

  return filledStyles[type];
};

export const FltButtonWrapper = styled.button<ButtonProps>`
  appearance: none;
  padding: 0px;
  margin: 0px;
  border: 1px solid rgb(0, 104, 74);
  display: inline-flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  transition: 150ms ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  z-index: 0;
  transform: translateY(1px);
  text-wrap: nowrap;
  ${({ btnFor = 'normal-btn', btnType = 'primary', appearance = 'filled' }) =>
    getButtonStyles(btnType, appearance, btnFor)}
  ${({ shape = 'rectangle' }) => shapeStyles[shape]}
  ${({ size = 'default' }) => sizes[size]}

  &:focus {
    outline: none;
  }

  .btn-content {
    display: grid;
    grid-auto-flow: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    width: 100%;
    pointer-events: none;
    position: relative;
    z-index: 0;
    gap: 6px;
    ${({ childLayout = 'default' }) => layouts[childLayout]}
  }
`;
