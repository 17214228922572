import { AppState } from '@src/app/rootReducer';
import FltIcon from '@src/components/base/fltIcon/fltIcon';
import FltText from '@src/components/base/fltText/fltText';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../header/header';
import { getTeamsDetailsNavBarData, settingNavBarData } from '../functions/navData';
import { INavbarData } from '../mainLayoutInterface';
import { SecondarySidebarProps } from './secondarySidebarInterface';
import { SecondarySidebarWrapper } from './secondarySidebarStyles';

const SecondarySidebar = (props: SecondarySidebarProps) => {
  const theme = useSelector((data: AppState) => data.theme.theme);
  const themeMode = useSelector((data: AppState) => data.theme);
  const [navData, setNavData] = useState<INavbarData[]>([]);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(location.pathname);
  useEffect(() => {
    setActiveItem(location.pathname);
    if (location.pathname.includes('settings')) {
      setNavData(settingNavBarData);
    } else if (location.pathname.includes('details')) {
      setNavData(getTeamsDetailsNavBarData(id));
    } else {
      setNavData([]);
    }
  }, [location]);

  return (
    <SecondarySidebarWrapper theme={theme}>
      <Header activeItem={activeItem} baseRoute={navData && navData[0]?.baseRoute}></Header>
      <div className="secondary-main-div ">
        <div className="secondary-sidebar lg-hide">
          <div className="secondary-sidebar-items ">
            <ul className="secondary-sidebar-sidenav ">
              {navData &&
                navData?.map((data, index) => {
                  return (
                    <li className="sidenav-nav-item " key={index}>
                      {data.items && data?.items.length > 0 && (
                        <div className="nav-label ">
                          <FltText variant="heading-small">{data.label}</FltText>
                        </div>
                      )}
                      {data.items && data.items.length && (
                        <div className="subnav-sub-items-wrapper">
                          <ul className="sidenav-sub-items ">
                            {data.items.map((data, navIndex) => {
                              return (
                                <li
                                  className={`sidenav-submenu-item-wrapper  ${activeItem === data.routeLink ? 'active-item' : ''
                                    }`}
                                  key={navIndex}
                                  onClick={() => {
                                    navigate(data.routeLink);
                                    // Step 3: Update the active item when clicked
                                    setActiveItem(data.routeLink);
                                  }}
                                >
                                  <div
                                    className="sidenav-submenu-item"
                                    onClick={() => {
                                      // navClick(data, navIndex);
                                    }}
                                  >
                                    <FltIcon
                                      name={(data as { icon: string }).icon}
                                      size={16}
                                      weight={activeItem === data.routeLink ? 'bold' : 'regular'}
                                    />
                                    <FltText
                                      variant="heading-medium"
                                      styles={{
                                        color: theme.pages.layout.secondarySidebar.text.primary,
                                      }}
                                    >
                                      {data.label}
                                    </FltText>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="secondary-sidebar-body ">{props.children}</div>
      </div>
    </SecondarySidebarWrapper>
  );
};

export default SecondarySidebar;
