import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '@src/features/auth/authSlice';
import themeReducer from '@src/features/theme/themeSlice';
import displayNameReducer from '@src/features/displayName/displayNameSlice';
import preferenceReducer from '@src/features/entity/preference/preferenceSlice';
import periodsReducer from '@src/features/entity/periods/periodsSlice';
import groupsReducer from '@src/features/entity/groups/groupsSlice';
import userRelshipReducer from '@src/features/entity/userRelship/userRelshipSlice';
import teamsReducer from '@src/features/entity/teams/teamsSlice';
import initiativesReducer from '@src/features/entity/initiatives/initiativeSlice';
import programsReducer from '@src/features/entity/programs/programsSlice';
import sideBarReducer from '@src/features/sideBar/sideBarSlice';
import usersReducer from '@src/features/entity/users/userRelshipSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  theme: themeReducer,
  displayName: displayNameReducer,
  preference: preferenceReducer,
  periods: periodsReducer,
  groups: groupsReducer,
  userRelship: userRelshipReducer,
  teams: teamsReducer,
  initiatives: initiativesReducer,
  programs: programsReducer,
  sideBar: sideBarReducer,
  users: usersReducer
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
