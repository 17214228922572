import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { AppState } from '@src/app/rootReducer';
import { useSelector } from 'react-redux';

export const FltTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: useSelector((state: AppState) => state.theme.theme.menu.bg), // Specified RootState for useSelector
    color: useSelector((state: AppState) => state.theme.theme.menu.textSecondary), // Specified RootState for useSelector
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
