import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { AuthState, CustomJwtPayload } from './authTypes';
import { clearLocalStorageKeys, deleteFluteDatabase } from './function';

const initialState: AuthState = {
  loggedIn: false,
  accessToken: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<AuthState>) => {
      console.log(action)
      const decodedToken = jwtDecode(action.payload.accessToken) as CustomJwtPayload;
      state.loggedIn = action.payload.loggedIn;
      state.accessToken = action.payload.accessToken;
      state.userId = decodedToken.userId;

      // Set theme in localStorage if provided
      if (action.payload.themeMode) {
        localStorage.setItem('Theme', action.payload.themeMode);
      }
    },
    logOut: (state) => {
      state.loggedIn = false;
      state.accessToken = '';
      state.userId = undefined;
      // Clear specific localStorage keys
      clearLocalStorageKeys();

      // Delete the 'flute' IndexedDB database
      deleteFluteDatabase();
    },
  },
});

export const { logIn, logOut } = authSlice.actions;
export default authSlice.reducer;
