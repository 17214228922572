import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Groups } from '@src/dbSyncManager/tables/groups.entity';

interface GroupsState {
  data: any[];
}

const initialState: GroupsState = {
  data: [],
};

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    setPrograms(state, action: PayloadAction<Groups[]>) {
      state.data = action.payload;
    },
  },
});

export const { setPrograms } = programsSlice.actions;
export default programsSlice.reducer;
