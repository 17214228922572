import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';
export const SecondarySidebarWrapper = styled.div<{ theme: Theme }>`
  /* height: 100%; */
  /* min-height: 100%; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* min-height: 100%; */
  height: 100vh;
  margin: 8px 12px 8px 0px;
  border-radius: 6px;
  border: ${({ theme }) =>`1px solid ${theme.pages.layout.secondarySidebar.border.primary}` };
  .secondary-main-div {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .secondary-sidebar-header {
    min-height: 57px;
    padding: 0 30px;
    background-color: ${({ theme }) => theme.pages.background.primary};
    span {
      color: ${({ theme }) => theme.pages.text.primary};
    }
  }

  .secondary-sidebar {
    min-height: calc(100vh - 57px);
    min-width: 180px;
    padding: 20px ;
    background: ${({ theme }) => theme.pages.layout.secondarySidebar.background.primary};
    border-right: ${({ theme }) =>
    `1px solid ${theme.pages.layout.secondarySidebar.border.primary}`};
    user-select: none;
    .secondary-sidebar-items {
      display: flex;
      flex-direction: column;
    }
    .secondary-sidebar-sidenav {
      gap: 20px;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
    }

    .sidenav-nav-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      .nav-label {
        padding: 0px 6px;
        gap: 10px;
        display: flex;
        width: 100%;
        align-items: center;
        span {
          color: ${({ theme }) => theme.pages.layout.secondarySidebar.text.primary};
        }
      }
    }

    .sidenav-sub-items {
      padding: 0 !important;
      gap: 4px;
      display: flex;
      flex-direction: column;

      .sidenav-submenu-item-wrapper {
        padding: 6px;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;
        /* transition: background-color 0.3s; */

        &:hover {
          /* background-color: ${({ theme }) =>
    theme.pages.layout.secondarySidebar.background.hover.primary}; */
          background-color:rgba(9, 10, 12, 0.06);
          .sidenav-submenu-item {
            /* *,
            > span {
              color: ${({ theme }) =>
    theme.pages.layout.secondarySidebar.text.hover.primary} !important;
            } */
          }
        }
        &.active-item {
          border-radius: 6px;
          /* border-radius: 5px; */
          /* background: ${({ theme }) => theme.pages.layout.secondarySidebar.background.primary}; */
          /* box-shadow: ${({ theme }) => theme.pages.layout.secondarySidebar.boxShadow.primary}; */
          background-color:rgba(9, 10, 12, 0.06);

          .sidenav-submenu-item {
            *,
            > span {
              color: ${({ theme }) =>
    theme.pages.layout.secondarySidebar.text.focus.primary} !important;
            }
          }
        }

        &:active {
          background-color: ${({ theme }) =>
    theme.pages.layout.secondarySidebar.background.active.primary};
          .sidenav-submenu-item {
            *,
            > span {
              color: ${({ theme }) =>
    theme.pages.layout.secondarySidebar.text.active.primary} !important;
            }
          }
        }

        .sidenav-submenu-item {
          display: flex;
          align-items: center;
          text-wrap: nowrap;
          gap: 6px;
          align-self: stretch;
        }
      }
    }
  }
  .secondary-sidebar-body {
    height: calc(100vh - 57px);
    background: ${({ theme }) => theme.pages.layout.secondarySidebar.background.primary};
    width: 100%;
    /* padding: 24px 40px */
  }

  .subnav-sub-items-wrapper {
    /* padding: 16px; */
    border-radius: 12px;
    /* background: ${({ theme }) => theme.pages.layout.sidebar.background.primary}; */
    background-color: transparent;
    /* border: ${({ theme }) => `1px solid ${theme.pages.layout.secondarySidebar.border.primary}`}; */
    width: 100%;
  }
`;
