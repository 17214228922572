export const saveState = (state: any) => {
    try {
      const { auth } = state;
    if (auth.loggedIn) {
      const serialState = JSON.stringify({ auth });
      localStorage.setItem('appState', serialState);
    } else {
      localStorage.removeItem('appState');
    }
    } catch (err) {
      console.log(err);
    }
  };
  
  export const loadState = () => {
    try {
      const serialState = localStorage.getItem('appState');
      if (serialState === null) {
        return undefined;
      }
      return JSON.parse(serialState);
    } catch (err) {
      return undefined;
    }
  };
  