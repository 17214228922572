import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

export const HeaderWrapper = styled.div<{ theme: Theme }>`
  width: 100%;
  /* min-height: 60px; */
  padding: 6px 20px;
  transition: opacity 0.05s ease-in-out 0s;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.pages.layout.header.background.secondary};
  border-bottom: ${({ theme }) => `1px solid ${theme.pages.layout.header.border.primary}`};
  height: 40px;
  .left-container {
    /* gap: 4px; */
    height: 100%;
    min-width: 0px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.pages.layout.header.text.secondary};
    flex: 1;
    .icon-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      /* padding: 0px 6px; */
    }
    .side-bar-toggel-btn-container{
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
    .divider {
      color: ${({ theme }) => theme.pages.layout.header.text.secondary};

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.36px;
      padding-left: 4px;
      padding-right: 4px;
      margin-top:2px;
    }
    .previous-page {
      cursor: pointer;
      text-align: left;
      overflow: hidden;
      font-style: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: ${({ theme }) => theme.pages.text.primary};
      line-height: normal;
      font-size: 13px;
      font-weight: 500;
    }
    .icon-div {
      display: flex;
    }

    .current-page {
      text-align: left;
      overflow: hidden;
      cursor: pointer;
      font-style: normal;
      color: ${({ theme }) => theme.pages.text.secondary};
      line-height: normal;
      font-size: 13px;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .right-container {
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({ theme }) => theme.pages.layout.header.text.secondary}; 
    margin-right: 6px;
    .search-anything-btn{
      display: flex;
      align-items: center;
      border:1px solid var(--grey-20);
      height: 28px;
      border-radius: 6px;
      gap: 10px;
      padding : 0px 10px;
      cursor: pointer;
      background-color: transparent;
      margin-right: 12px;
      border:${({ theme }) => `1px solid ${theme.fltInput.border.primary}`};
      color: ${({ theme }) => theme.fltInput.text.primary};
      background-color: ${({ theme }) => theme.fltInput.background.primary};
    }
    .search-anything-btn:hover{
      box-shadow: rgba(0, 0, 0, 0.02) 0px 4px 4px -1px, rgba(0, 0, 0, 0.06) 0px 1px 1px 0px;
    }
  }

  @media (max-width: 540px) {
    padding: 0 20px;
  }
`;
