// Utility function to clear specific localStorage keys
export const clearLocalStorageKeys = () => {
  // Remove keys starting with 'displayPreferences' and 'appState'
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith('displayPreferences') || key === 'appState' || key === 'SyncStatus') {
      localStorage.removeItem(key);
    }
  });
};

// Utility function to delete the IndexedDB database named 'flute'
export const deleteFluteDatabase = () => {
  const request = indexedDB.deleteDatabase('flute');
  request.onsuccess = () => {
    console.log('flute database deleted successfully');
  };
  request.onerror = (event) => {
    console.error('Error deleting flute database:', event);
  };
};