import { AppState } from '@src/app/rootReducer';
import { useSelector } from 'react-redux';
import { FltButtonProps } from './fltIconButtonInterface';
import { FltButtonWrapper } from './fltIconButtonStyles';
const FltIconButton = ({
  size = 'default',
  children,
  type,
  isLoading,
  action,
  disabled,
  onClick,
  shape,
  styles,
  onMouseEnter,
  onMouseLeave,
  btnStatus,
}: FltButtonProps) => {
  const theme = useSelector((data: AppState) => data.theme.theme);
  return (
    <FltButtonWrapper
      type={action}
      size={size}
      btnType={type}
      onClick={onClick}
      disabled={disabled}
      theme={theme}
      shape={shape}
      styles={styles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      btnStatus={btnStatus}
    >
      <div className="btn-content">
        {children}
        {isLoading && <>loading...</>}
      </div>
    </FltButtonWrapper>
  );
};

export default FltIconButton;
