import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SideBarState = {
  collapsed: boolean;
};

const initialState: SideBarState = {
  collapsed: false,
};

const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    toggelSideBar: (state) => {
      state.collapsed = !state.collapsed;
    },
  },
});

export const { toggelSideBar } = sideBarSlice.actions;
export default sideBarSlice.reducer;
