import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThemeState } from '@src/styles/themes/themeInterface';
import { darkTheme, lightTheme } from '@src/styles/themes/themes';
// Check local storage for the saved theme
const savedTheme = localStorage.getItem('Theme') as 'dark' | 'light' | null;

const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

// Determine initial theme
const initialTheme = savedTheme ? savedTheme : systemTheme;

const initialState: ThemeState = {
  mode: initialTheme,
  theme: initialTheme === 'light' ? lightTheme : darkTheme,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleColorMode: (state) => {
      console.log(state);
      state.mode = state.mode === 'light' ? 'dark' : 'light';
      state.theme = state.mode === 'light' ? lightTheme : darkTheme;
      localStorage.setItem('Theme', state.mode);
    },
    changeThemeMode: (state, action: PayloadAction<'dark' | 'light'>) => {
      state.mode = action.payload;
      state.theme = action.payload === 'dark' ? darkTheme : lightTheme;
      localStorage.setItem('Theme', action.payload);
    },
    setSystemTheme: (state) => {
      const currentSystemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
      state.mode = currentSystemTheme;
      state.theme = currentSystemTheme === 'light' ? lightTheme : darkTheme;
      localStorage.setItem('Theme', currentSystemTheme);
    },
  },
});

export const { toggleColorMode, changeThemeMode, setSystemTheme } = themeSlice.actions;
export default themeSlice.reducer;
