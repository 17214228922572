// Define the colors object with explicit typing
import { Theme } from '@src/styles/themes/themeInterface';
import styled, { css, CSSObject, RuleSet } from 'styled-components';
import { Appearance, ButtonProps, ChildLayout, Shape, Size, Type } from './fltButtonInterface';

// Define the sizes object with explicit typing
const sizes: { [key in Size]: RuleSet<object> } = {
  default: css`
    padding: 0px 11px;
    font-size: 12px;
    height: 26px;
    gap: 6px;
    svg {
      font-size: 20px;
    }
  `,
  'x-small': css`
    padding: 0px 11px;
    font-size: 10px;
    height: 24px;
    gap: 4px;
    svg {
      font-size: 20px;
    }
  `,
  small: css`
    padding: 4px 16px;
    font-size: 12px;
    height: 21px;
    gap: 4px;
    svg {
      font-size: 12px;
    }
  `,
  large: css`
    padding: 0px 15px;
    font-size: 13px;
    height: 36px;
    gap: 8px;
    svg {
      font-size: 18px;
    }
  `,
  'x-large': css`
    padding: 0px 15px;
    font-size: 15px;
    height: 48px;
    gap: 8px;
    svg {
      font-size: 18px;
    }
  `,
};

const layouts: { [key in ChildLayout]: RuleSet<object> } = {
  default: css`
    justify-content: center;
  `,
  'space-between': css`
    justify-content: space-between;
  `,
};

const shapeStyles: { [key in Shape]: RuleSet<object> } = {
  circle: css`
    border-radius: 50%;
    width:32px;
    padding: 0;
  `,
  rounded: css`
    border-radius: 20px;
  `,
  rectangle: css`
    border-radius: 5px;
  `,
};

// Define color styles
const filledStyles = {
  primary: css<{ theme: Theme }>`
    background-color: ${({ theme }) => theme.fltButton.background.primary};
    color: ${({ theme }) => theme.fltButton.text.primary};
    border: ${({ theme }) => `1px solid ${theme.fltButton.border.primary}`};
    box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.primary} 0 0 0 1px`};

    &:hover {
      box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.hover.primary} 0 0 0 1px`};
      background-color: ${({ theme }) => theme.fltButton.background.hover.primary};
    }
  `,
  secondary: css<{ theme: Theme }>`
    background-color: ${({ theme }) => theme.fltButton.background.secondary};
    color: ${({ theme }) => theme.fltButton.text.secondary};
    border: ${({ theme }) => `1px solid ${theme.fltButton.border.secondary}`};
    box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.primary}`};

    &:hover {
      box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.hover.secondary} 0 0 0 1px`};
      background-color: ${({ theme }) => theme.fltButton.background.hover.secondary};
    }
  `,
  tertiary: css<{ theme: Theme }>``,
  danger: css<{ theme: Theme }>`
    background-color: ${({ theme }) => theme.fltButton.background.danger};
    color: ${({ theme }) => theme.fltButton.text.primary};
    border: ${({ theme }) => `1px solid ${theme.fltButton.border.danger}`};
    box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.primary} 0 0 0 1px`};

    &:hover {
      box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.hover.danger} 0 0 0 1px`};
      background-color: ${({ theme }) => theme.fltButton.background.hover.danger};
    }
  `,
};

const outlineStyles = {
  primary: css<{ theme: Theme }>`
    background-color: transparent;
    color: ${({ theme }) => theme.fltButton.background.primary};
    border: ${({ theme }) => `1px solid ${theme.fltButton.border.primary}`};
    box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.primary} 0 0 0 1px`};


    &:hover {
      background-color: ${({ theme }) => theme.fltButton.background.primary};
      color: ${({ theme }) => theme.fltButton.text.primary};
      box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.hover.primary} 0 0 0 1px`};
    }
  `,
  secondary: css<{ theme: Theme }>`
    background-color: ${({ theme }) => theme.fltButton.background.secondary};
    color: ${({ theme }) => theme.fltButton.text.tertiary};
    border: ${({ theme }) => `1px solid ${theme.fltButton.border.secondary}`};
    box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.primary}`};
    box-shadow: 23px 297px 83px 0px rgba(184, 184, 184, 0.00), 15px 190px 76px 0px rgba(184, 184, 184, 0.01), 8px 107px 64px 0px rgba(184, 184, 184, 0.05), 4px 47px 48px 0px rgba(184, 184, 184, 0.09), 1px 12px 26px 0px rgba(184, 184, 184, 0.10);


    &:hover {
      background-color: ${({ theme }) => theme.fltButton.background.hover.secondary};
      box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.hover.secondary} 0 0 0 1px`};
    }
  `,
  tertiary: css<{ theme: Theme }>``,
  danger: css<{ theme: Theme }>`
    background-color: transparent;
    color: ${({ theme }) => theme.fltButton.background.danger};
    border: ${({ theme }) => `1px solid ${theme.fltButton.border.danger}`};
    box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.primary} 0 0 0 1px`};

    &:hover {
      background-color: ${({ theme }) => theme.fltButton.background.danger};
      color: ${({ theme }) => theme.fltButton.text.primary};
      box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.hover.danger} 0 0 0 1px`};
    }
  `,
};

const noneStyles = {
  primary: css<{ theme: Theme }>`
    background-color: inherit;
    color: ${({ theme }) => theme.fltButton.text.primary};
    border: none;
    box-shadow: none;

    &:hover {
    }
  `,
  secondary: css<{ theme: Theme }>`
    background-color: inherit;
    color: ${({ theme }) => theme.fltButton.text.secondary};
    border: none;
    box-shadow: none;

    &:hover {
    }
  `,
  tertiary: css<{ theme: Theme }>`
    background-color: inherit;
    color: ${({ theme }) => theme.fltButton.text.tertiary};
    border: none;
    box-shadow: none;

    &:hover {
    }
  `,
  danger: css<{ theme: Theme }>`
    background-color: inherit;
    color: ${({ theme }) => theme.fltButton.text.primary};
    border: none;
    box-shadow: none;

    &:hover {
    }
  `,
};

const getButtonStyles = (type: Type, appearance: Appearance) => {
  if (appearance === 'outline') {
    return outlineStyles[type];
  } else if (appearance == 'none') {
    return noneStyles[type];
  }
  return filledStyles[type];
};

export const FltDefaultButton = styled.button<ButtonProps>`
  align-items: center;
  display: inline-flex;
  appearance: none;
  padding: 0px;
  margin: 0px;
  transition: all 150ms ease -in -out;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  z-index: 0;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  text-wrap: nowrap;
  ${({ btnType = 'primary', appearance = 'filled' }) => getButtonStyles(btnType, appearance)}
  ${({ shape = 'rectangle' }) => shapeStyles[shape]}
  ${({ size = 'default' }) => sizes[size]}
  ${({ childLayout = 'default' }) => layouts[childLayout]}
  ${({ styles }) => styles && css(styles as CSSObject)}

  &:focus {
    outline: none;
  }
`;
