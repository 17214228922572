import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRelships } from '@src/dbSyncManager/tables/userRelships.entity';

interface UserRelshipState {
  data: any[];
}

const initialState: UserRelshipState = {
  data: [],
};

const userRelshipSlice = createSlice({
  name: 'user_relship',
  initialState,
  reducers: {
    setUserRelship(state, action: PayloadAction<UserRelships[]>) {
      state.data = action.payload;
    },
  },
});

export const { setUserRelship } = userRelshipSlice.actions;
export default userRelshipSlice.reducer;
