import Moment from 'moment';
export const formatDate = (date: string) => {
  try {
    const format = 'DD MMM YYYY';
    Moment.locale('en');
    if (date != null) return Moment(date).format(format);
    return '';
  } catch (e) {
    return '-';
  }
};

export const capitalizeFirstLetter = (data: string) => {
  if (typeof data !== 'string' || data.length === 0) {
    return ''; // Return empty string if the input is invalid
  }

  // Capitalize the first letter and convert the rest to lowercase
  return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
};

export const formatDateTime = (timestamp: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // Use 24-hour format
  };
  return new Date(timestamp).toLocaleString(undefined, options);
};

export const getTimeAgo = (timestamp: string): string => {
  const now = new Date();
  const diff = now.getTime() - new Date(timestamp).getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (days < 1) {
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    return new Date(timestamp).toLocaleString(undefined, options);
  } else if (days <= 7) {
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  } else if (weeks <= 4) {
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
  } else if (months <= 12) {
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  } else {
    return `${years} year${years !== 1 ? 's' : ''} ago`;
  }
};

export const formatReadable = (text: any) => {
  // Add spaces before capital letters (handling camelCase and PascalCase)
  let formattedText = text?.replace(/([a-z])([A-Z])/g, '$1 $2');
  // Replace snake_case and kebab-case with spaces
  formattedText = formattedText.replace(/[_-]/g, ' ');
  // Add spaces before sequences of capital letters followed by lowercase letters (handling cases like "Myaccount")
  formattedText = formattedText.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
  // Capitalize the first letter of each word
  formattedText = formattedText.replace(/\b\w/g, (char: any) => char.toUpperCase());

  return formattedText;
};

export const flattenArray = (nestedArray: any) => {
  return nestedArray.reduce((acc: any, currentArray: any) => acc.concat(currentArray), []);
};

export const sortByTimeDescending = (array: any) => {
  return array.sort((a: any, b: any) => {
    const timeA = new Date(a.time).getTime();
    const timeB = new Date(b.time).getTime();
    return timeB - timeA; // Descending order
  });
};

export function checkTokenExpiry() {
  // Get the stored appState from localStorage
  const storedState: any = localStorage.getItem('appState');

  // Parse the JSON string to an object
  const parsedState = JSON.parse(storedState);

  // Access the accessToken from the parsed object
  const token = parsedState?.auth?.accessToken;
  if (token) {
    const decodedToken = JSON.parse(atob(token.split('.')[1])); // Assuming the token is in JWT format
    const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();
    if (currentTime > expirationTime) {
      // Token expired, perform cleanup and redirect
      clearLocalStorageAndIndexDB();
      redirectToLoginPage();
      return false;
    }
    return true;
  } else {
    // Token not found, redirect to login page
    redirectToLoginPage();
    return false;
  }
}

export function clearLocalStorageAndIndexDB() {
  // Clear token from local storage
  localStorage.removeItem('appState');
  localStorage.removeItem('SyncStatus');
  // const storedAppStateString = localStorage.getItem('appState');
  // let storedAppState: any = null;
  // if (storedAppStateString !== null) {
  //   storedAppState = JSON.parse(storedAppStateString);
  // }

  // if (storedAppState && storedAppState.auth && storedAppState.auth.accessToken) {
  //   storedAppState.auth.accessToken = '';
  //   storedAppState.auth.loggedIn = false;
  //   localStorage.setItem('appState', JSON.stringify(storedAppState));
  // }

  // Clear IndexedDB data
  var request = indexedDB.deleteDatabase('flute');
  request.onsuccess = function () {
    console.log('IndexedDB cleared');
  };
}

export function redirectToLoginPage() {
  window.location.href = '/login'; // Redirect to your login page
}

import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../dbSyncManager/init'; // Import your database setup

export const getDisplayName = (type: string, id?: any) => {
  const authUserString: any = window.localStorage?.getItem('appState') || '';
  const accessToken: any = JSON.parse(authUserString || '{}')?.auth?.accessToken || '';
  const loggedInUserId: any = JSON.parse(authUserString || '{}')?.auth?.userId || '';
  const userId = id || loggedInUserId;
  return useLiveQuery(async () => {
    const userList = await db.users.toArray();
    const user = userList.find((user: any) => user.id === userId);
    if (!user) return ''; // Return an empty string if user is not found

    const { firstName, lastName, preferredName } = user;

    // Function to filter out null/undefined values and join the remaining parts
    const joinNameParts = (parts: (string | undefined)[]) => {
      return parts.filter((part) => part).join(' ');
    };

    if (type === 'FirstName LastName (Preferred Name)') {
      return joinNameParts([firstName, lastName, preferredName ? `(${preferredName})` : undefined]);
    }
    if (type === 'LastName FirstName (Preferred Name)') {
      return joinNameParts([lastName, firstName, preferredName ? `(${preferredName})` : undefined]);
    }

    return ''; // Default return if no matching type
  }, [type, userId]);
};

export const parseFunctions = (key: any, value: any) => {
  if (typeof value === 'string' && (value.includes('params') || value.includes('function'))) {
    // Parse function strings
    const functionTemplate = `(${value})`;
    try {
      // Use eval to create a function
      return eval(functionTemplate);
    } catch (error) {
      // If eval fails, return the original string
      return value;
    }
  } else if (typeof value === 'object') {
    // If the value is an object, recursively parse its properties
    for (const prop in value) {
      value[prop] = parseFunctions(prop, value[prop]);
    }
  }
  return value;
};

export const hexToRGBA = (hex: string, opacity: number = 0.12): string => {
  // Remove the leading '#' if present
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const removeTrailingColon = (str: string) => (str.endsWith(':') ? str.slice(0, -1) : str);
