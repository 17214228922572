type IconsType = {
    [key:string] : JSX.Element;
};
export const LocalIcons: { [key: string]: React.FC<any> } = {
  IconOne: (props) => <svg {...props}>...</svg>, // Replace with actual icon SVGs
  IconTwo: (props) => <svg {...props}>...</svg>,
  // Add more icons here
};
export const LocalIcons1: IconsType = {
'rocket':
 <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
  <path d="M0 9.47838L2.31198 7.20978L6.24828 6.51511L5.85918 8.56672L0.537669 10.1956L0.193844 10.0476L0 9.47838Z" fill="#CA2C31"/>
  <path d="M2.7124 7.79173L0.202333 10.0631L2.0488 10.9593L4.85176 9.53475V8.86262L5.4248 7.32391L2.7124 7.79173ZM8.65223 11.7921L7.43965 12.3163L6.29498 14.7328C6.29498 14.7328 7.3024 16.9549 7.34768 16.9549C7.39295 16.9549 7.966 17 7.966 17L10.0035 14.7314L10.5185 10.8565L8.65223 11.7921Z" fill="#A02422"/>
  <path d="M7.46087 13.5802C7.46087 13.5802 8.13579 13.4435 8.61686 13.2152C9.09793 12.987 9.6922 12.646 9.6922 12.646L9.4743 14.5384L7.34485 16.9521C7.34485 16.9521 7.26137 16.8492 6.90764 16.0827C6.62607 15.4712 6.52844 15.2528 6.52844 15.2528L7.46087 13.5802Z" fill="#CA2C31"/>
  <path d="M3.93489 10.7367C3.93489 10.7367 3.12273 10.669 2.23274 11.7893C1.42058 12.8122 1.03997 14.4778 0.892814 14.9061C0.745663 15.3345 0.393348 15.4218 0.510786 15.6177C0.628224 15.8136 1.13618 15.8023 1.76299 15.7248C2.3898 15.6473 2.92747 15.4514 2.92747 15.4514C2.92747 15.4514 2.90058 15.5191 2.81003 15.6656C2.77749 15.7177 2.66429 15.7924 2.67278 15.8699C2.69259 16.0545 4.28013 16.0587 5.5479 14.7596C6.91754 13.3576 6.25253 12.2078 6.25253 12.2078L3.93489 10.7367Z" fill="#F7D74D"/>
  <path d="M4.17967 11.3891C4.17967 11.3891 3.30384 11.4356 2.5794 12.5558C1.85496 13.676 1.51821 14.9738 1.58754 15.0611C1.65546 15.1485 3.00529 14.6624 3.3293 14.7596C3.55145 14.8272 3.43684 15.0513 3.49627 15.1104C3.54579 15.1598 4.13156 15.2429 5.07956 14.2199C6.02897 13.1969 5.804 11.7597 5.804 11.7597L4.17967 11.3891Z" fill="#FBF0B4"/>
  <path d="M4.60981 9.86024C4.60981 9.86024 4.16977 10.2506 4.01271 10.4746C3.85566 10.6986 3.85566 10.7184 3.86556 10.786C3.87546 10.8536 4.03818 11.5962 4.71593 12.2472C5.5479 13.0461 6.29073 13.1532 6.39827 13.1532C6.5058 13.1532 7.22033 12.3543 7.22033 12.3543L4.60981 9.86024Z" fill="#858585"/>
  <path d="M6.55532 12.2275C6.55532 12.2275 7.26844 12.6939 8.46405 12.2937C10.0983 11.747 11.2967 10.5239 12.781 8.97957C14.2129 7.48878 15.4141 5.63867 15.4141 5.63867L14.5723 2.83039L6.55532 12.2275Z" fill="#437687"/>
  <path d="M9.08661 10.6902L9.28329 10.0815L9.77568 10.1505C9.77568 10.1505 10.1506 10.3266 10.4265 10.4549C10.7025 10.5831 11.0576 10.6803 11.0576 10.6803L10.3572 11.2693C10.3572 11.2693 9.97518 11.1256 9.69644 11.0044C9.38233 10.8677 9.08661 10.6902 9.08661 10.6902Z" fill="#3F545F"/>
  <path d="M11.3972 1.64536C11.3972 1.64536 10.036 2.43162 8.08485 4.27046C6.3261 5.92753 5.02296 7.42678 4.70744 8.70199C4.44426 9.77006 4.58434 10.4182 5.18285 11.1185C5.81532 11.8597 6.55674 12.2275 6.55674 12.2275C6.55674 12.2275 7.11421 12.2359 9.44034 10.4535C12.3423 8.22854 14.6671 4.50296 14.6671 4.50296L11.3972 1.64536Z" fill="#8DAFBF"/>
  <path d="M14.2072 5.26667C14.2072 5.26667 13.0229 4.76363 12.1782 3.58988C11.3392 2.42458 11.4057 1.63691 11.4057 1.63691C11.4057 1.63691 12.1075 1.08878 13.6851 0.461743C14.7463 0.0404313 16.1909 -0.175156 16.6663 0.175702C17.1417 0.526561 16.3409 2.28649 16.3409 2.28649L14.8821 5.09899L14.2072 5.26667Z" fill="#D83F22"/>
  <path d="M9.11208 10.7C9.11208 10.7 8.08909 10.1801 7.43823 9.44034C6.20725 8.04395 5.95257 6.51088 5.95257 6.51088L6.57089 5.78803C6.57089 5.78803 6.72511 7.41691 8.04523 8.82457C8.9055 9.74188 9.80398 10.1618 9.80398 10.1618C9.80398 10.1618 9.62428 10.3126 9.43185 10.4591C9.30875 10.5535 9.11208 10.7 9.11208 10.7Z" fill="#6896A5"/>
  <path d="M15.4141 5.63867C15.4141 5.63867 16.0282 4.90172 16.6097 3.24043C17.4219 0.923918 16.7144 0.222201 16.7144 0.222201C16.7144 0.222201 16.4753 1.32409 15.6448 2.85575C14.9783 4.08305 14.1718 5.25117 14.1718 5.25117C14.1718 5.25117 14.6161 5.44985 14.8567 5.5189C15.1594 5.60626 15.4141 5.63867 15.4141 5.63867Z" fill="#A02422"/>
  <path d="M5.09937 9.18671C5.28331 9.36143 5.56205 9.17825 5.74457 8.74989C5.92851 8.32154 5.92568 8.082 5.78419 7.98054C5.60874 7.85373 5.31444 8.13131 5.18568 8.3779C5.04419 8.65126 4.96071 9.05425 5.09937 9.18671ZM11.4863 2.20758C11.4863 2.20758 10.4096 2.97834 9.26065 4.02669C8.20653 4.98909 7.50756 5.74153 7.41135 5.91625C7.30947 6.10366 7.30806 6.36857 7.38729 6.50384C7.46653 6.63911 7.64198 6.70956 7.80187 6.5912C7.96175 6.47143 9.10076 5.21736 10.1294 4.32119C11.0703 3.50111 11.7962 2.98116 11.7962 2.98116C11.7962 2.98116 12.0905 2.46262 12.0424 2.34426C11.9943 2.22449 11.4863 2.20758 11.4863 2.20758Z" fill="#B3E1EE"/>
  <path d="M11.4863 2.20758C11.4863 2.20758 12.2815 1.60027 13.1474 1.17332C13.9525 0.775966 14.7534 0.444834 14.8977 0.732285C15.0349 1.00564 14.1379 1.37764 13.3483 1.87927C12.5588 2.3809 11.799 2.97975 11.799 2.97975C11.799 2.97975 11.683 2.76839 11.608 2.57253C11.5604 2.45331 11.5198 2.33146 11.4863 2.20758Z" fill="#ED6A65"/>
  <path d="M12.1428 4.90172C11.3547 4.17887 10.2582 4.37332 9.71767 5.01586C9.16302 5.67812 9.20405 6.68983 9.85916 7.29855C10.4548 7.85091 11.5627 7.97631 12.2306 7.20133C12.8248 6.5137 12.788 5.49212 12.1428 4.90172Z" fill="#E1E1E1"/>
  <path d="M10.1252 5.2709C9.65682 5.72885 9.68229 6.63629 10.2398 7.05056C10.7265 7.41269 11.417 7.39437 11.8499 6.95333C12.2829 6.51229 12.2829 5.71053 11.8768 5.29626C11.4099 4.82141 10.6614 4.74673 10.1252 5.2709Z" fill="#3F545F"/>
  <path d="M6.54117 10.2055C6.54117 10.2055 7.41701 9.32057 7.51322 9.41638C7.60944 9.5122 7.5967 10.0491 7.17789 10.6465C6.75765 11.2439 5.83371 12.3078 5.1022 13.025C4.38059 13.731 3.36326 14.5384 3.26705 14.5243C3.19206 14.513 2.88785 14.3622 2.76617 14.2157C2.64731 14.0705 3.00246 13.3843 3.14537 13.1575C3.28969 12.9306 6.54117 10.2055 6.54117 10.2055Z" fill="#A02524"/>
  <path d="M2.47045 13.7225C2.45913 13.9254 2.77041 14.2213 2.77041 14.2213L7.51322 9.41779C7.51322 9.41779 7.29108 9.16839 6.63739 9.57279C5.97803 9.98142 5.40075 10.4718 4.54897 11.2933C3.36185 12.4402 2.48177 13.5196 2.47045 13.7225Z" fill="#CA2C31"/>
  <path d="M2.16341 4.57764C2.00636 4.73404 1.99928 4.98486 2.17473 5.12858C2.33037 5.25681 2.58364 5.17367 2.67844 5.05813C2.77324 4.94259 2.76192 4.69177 2.63599 4.56777C2.51007 4.44237 2.25963 4.48323 2.16341 4.57764Z" fill="white"/>
  <path d="M5.27057 4.39446C5.15172 4.5255 5.18992 4.71995 5.30029 4.79182C5.41065 4.86368 5.5762 4.85663 5.65685 4.758C5.72901 4.66923 5.7375 4.49168 5.62713 4.38178C5.53092 4.28737 5.34698 4.30991 5.27057 4.39446Z" fill="white"/>
  <path d="M7.32645 1.04651C7.32645 1.04651 7.49907 0.556151 7.60095 0.550515C7.70424 0.544878 7.8528 1.04087 7.8528 1.04087C7.8528 1.04087 8.36359 1.04651 8.39755 1.12119C8.44141 1.217 8.0707 1.53827 8.0707 1.53827C8.0707 1.53827 8.19097 2.01736 8.13437 2.07513C8.0707 2.13854 7.63066 1.88631 7.63066 1.88631C7.63066 1.88631 7.17789 2.20054 7.08026 2.11458C6.99536 2.0399 7.17223 1.53264 7.17223 1.53264C7.17223 1.53264 6.74775 1.22405 6.77039 1.1381C6.80293 1.01692 7.32645 1.04651 7.32645 1.04651ZM13.1927 12.8038C13.3639 12.8982 13.579 12.8447 13.6582 12.6629C13.7304 12.5008 13.5974 12.3078 13.4771 12.2557C13.3568 12.2035 13.146 12.2726 13.0696 12.4092C12.9946 12.5445 13.0894 12.7474 13.1927 12.8038ZM15.6235 8.64844C15.5259 8.49767 15.3151 8.44835 15.1778 8.56953C15.0448 8.68789 15.0774 8.87389 15.1524 8.9683C15.2274 9.06271 15.4283 9.09793 15.55 9.02044C15.6716 8.94294 15.683 8.73862 15.6235 8.64844Z" fill="white"/>
</svg>, 
} 