import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Preference } from '@src/dbSyncManager/tables/preference.entity';

interface PreferenceState {
  data: any[];
}

const initialState: PreferenceState = {
  data: [],
};

const preferenceSlice = createSlice({
  name: 'preference',
  initialState,
  reducers: {
    setPreference(state, action: PayloadAction<Preference[]>) {
      state.data = action.payload;
    },
  },
});

export const { setPreference } = preferenceSlice.actions;
export default preferenceSlice.reducer;
