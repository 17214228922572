import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

export const SidebarItemsWrapper = styled.div<{ theme: Theme; mode: string; collapsed: boolean }>`
  display: flex;
  padding: 0px 14px 0px 14px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 10px; */
  align-self: stretch;
  position: relative;
  overflow: visible; /* Ensure that the child can overflow */
  user-select: none;
  gap: 4px;
  .hierarchy-ver-line{
    position: absolute;
    width: 1px;
    background-color:${({ theme }) => theme.pages.layout.sidebar.text.quaternary};
    height: 141px;
    bottom: 18px;
    left: 15px;
  }
  .hierarchy-har-line{
    position: absolute;
    width: 10px;
    height: 20px;
    bottom: 14px;
    left: -2px;
    border-left: ${({ theme }) => `1px solid ${theme.pages.layout.sidebar.text.quaternary}`};
    border-bottom: ${({ theme }) => `1px solid ${theme.pages.layout.sidebar.text.quaternary}`};
    border-radius: 0px 0px 0px 5px;
    
  }
  .parent-item {
    display: flex;
    /* padding: 8px 14px; */
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 4px;
    border-radius: 6px;
    height : 28px;
    .icon-name-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 6px;
      /* width: 100%; */
      max-width: 85%;
      border-radius: 4px;
      /* color: ${({ theme }) => theme.pages.layout.sidebar.text.secondary}; */
      cursor: pointer;
      height : 28px;
      margin-bottom: 1px;
      /* transition: background-color 0.3s; */
      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width:  20px;
        height: 20px;
        flex-shrink: 0;
        /* background-color:rgba(0, 178, 190, 0.12); */
        border-radius: 4px;
        font-size: 16px;
        margin-left: 6px;
      }
      
      .text-wrapper {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
      }
    }
    .right-side-item{
      display: flex;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    padding: 2px 0px 2px 4px;
    margin-right: 6px;
    }
  }

  .parent-item:hover {
    background-color: ${({ mode }) => mode == 'light' ? '#d9dde1' : 'rgba(255, 255, 255, 0.08)'};
    }

  .child-item-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    padding-left: 17px;
  }

  .child-item-container-float {
    position: absolute;
    z-index: 1000;
    width: 220px;
  }
`;