// SnackbarProviderWrapper.tsx
import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';

interface SnackbarProviderWrapperProps {
    children: ReactNode;
}
  
const SnackbarProviderWrapper: React.FC<SnackbarProviderWrapperProps> = ({ children }) => {
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
      {children}
    </SnackbarProvider>
  );
};

export default SnackbarProviderWrapper;
