import { PaletteMode, ThemeProvider, createTheme } from '@mui/material';
import { AppState } from '@src/app/rootReducer';
import { toggleColorMode } from '@src/features/theme/themeSlice';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MainLayoutProps } from './mainLayoutInterface';
import { MainLayoutWrapper, PageContainer } from './mainLayoutStyles';
import { PrimarySideBar } from './primarySidebar/primarySidebar';
import SecondarySidebar from './secondarySidebar/secondarySidebar';

type ModelType = {
  theme: {
    mode: PaletteMode | undefined;
  };
};
const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export const MainLayout = ({ children }: MainLayoutProps) => {
  const dispatch = useDispatch();
  const mode = useSelector((state: ModelType) => state.theme.mode);
  const themes = useSelector((state: AppState) => state.theme.theme);
  const isSideCollapse = useSelector((state: AppState) => state.sideBar.collapsed);
  const { id } = useParams();

  const handleToggleColorMode = () => {
    dispatch(toggleColorMode());
  };

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode,
    }),
    []
  );

  const navbarRef = useRef<HTMLDivElement>(null); // reference to common popup
  const topbarRef = useRef<HTMLDivElement>(null); // reference to common popup

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  // localStorage.setItem('Theme', theme.palette.mode);
  const navigate = useNavigate();
  const [collapse,setCollapse]=useState(false);
  const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth < 577);
  const [isTabView, setIsTabView] = useState<boolean>(
    window.innerWidth > 600 && window.innerWidth <= 1024
  );
  const location = useLocation();
  useEffect(()=>{
    if(typeof(isSideCollapse) == "boolean"){
      setCollapse(isSideCollapse)
    }
  },[isSideCollapse])
  // Add an event listener to check and update the mobile view state
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 577);
      setIsTabView(window.innerWidth > 600 && window.innerWidth < 901);
      if (window.innerWidth < 577) {
  
        setCollapsed(true);
        showSideBar(false);
      } else {
        setCollapsed(false);
        showSideBar(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [collapsed, setCollapsed] = React.useState<null | boolean>(isMobileView ? true : false);
  const [sidebar, showSideBar] = React.useState<null | boolean>(isMobileView ? false : true);
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        navbarRef &&
        navbarRef.current &&
        !navbarRef.current.contains(e.target as Node) &&
        topbarRef &&
        topbarRef.current &&
        !topbarRef.current.contains(e.target as Node) &&
        isMobileView &&
        !isTabView
      ) {
        showSideBar(!sidebar);
      }
    };

    if (sidebar) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [sidebar, showSideBar, isMobileView]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <MainLayoutWrapper theme={themes} collapsed={collapse} >
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
            {/* <SideBarWrapper2 theme={themes}>
              <div className="sidebar-header">
                <div className='left-container'>
                  <img src={Logo} title="flute" alt="flute" width="28px" height="28px" />
                  <FltText variant='display-large'>Flute</FltText>
                </div>
                <div className='rigth-container'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path d="M20.1392 13.7502V8.25016C20.1392 3.66683 18.3059 1.8335 13.7226 1.8335H8.22258C3.63924 1.8335 1.80591 3.66683 1.80591 8.25016V13.7502C1.80591 18.3335 3.63924 20.1668 8.22258 20.1668H13.7226C18.3059 20.1668 20.1392 18.3335 20.1392 13.7502Z" stroke="#8D9CAB" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.30591 1.8335V20.1668" stroke="#8D9CAB" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M13.7224 8.65332L11.3757 11L13.7224 13.3467" stroke="#8D9CAB" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>

              </div>
              <div className='create-btn-container'>
                <FltButton size='large' style={{ backgroundColor: "#024EAE", width: "100%", padding: "10px 14px", height: "36px", borderRadius: "8px" }}>Create + </FltButton>
              </div>
              <div className='divider'></div>
              <div>
                <div className='nav-item-group-title-container'>
                  <FltText variant='label-small' style={{ color: '#6C7F93' }}>Main Menu</FltText>
                </div>
                {mainMenuSidebarItems.map(item => (
                  <SidebarItem key={item.id} item={item} />
                ))}
                <div className='divider'></div>
                <div className='nav-item-group-title-container'>
                  <FltText variant='label-small' style={{ color: '#6C7F93' }}>Company Workspace</FltText>
                  {navIcons["arrow-up"]}
                </div>
                {companyWorkspaceSidebarItems.map(item => (
                  <SidebarItem key={item.id} item={item} />
                ))}
                <div className='divider'></div>
                <div className='nav-item-group-title-container'>
                  <FltText variant='label-small' style={{ color: '#6C7F93' }}>Your Teams</FltText>
                </div>
                {yourTeamsSidebarItems.map(item => (
                  <SidebarItem key={item.id} item={item} />
                ))}
                <div className='divider'></div>
              </div>

            </SideBarWrapper2> */}
            <PrimarySideBar collapse={collapse} setCollapse={setCollapse} />
            {/* {sidebar && (
              <SideBarWrapper
                ref={navbarRef}
                theme={themes}
                className={' sidenav ' + `${collapsed ? 'sidenav-collapsed' : 'sidesubnav-hover'}`} //paddingRight:collapsed?"15px":"0px"
              >
                <div className=' logo-container'
                  style={{
                    paddingRight: collapsed ? '48px' : '0px',
                  }}
                >
                  <img src={Logo2} title="flute" alt="flute" width="30px" height="30px" />
                  {collapsed && (
                    <span className='company-name-div'>
                      Flute
                    </span>
                  )}
                </div>
                <div className="scrollwrap scrollwrap_delayed">
                  <ul className=" sidenav-nav">
                    {navData.map((data, index) => {
                      return (
                        <li key={`side_nav_${index}`} className="sidenav-nav-item">
                          {data.items && data.items.length > 0 && (
                            <a
                              className={
                                ' sidenav-nav-link sidenav-nav-hover-link1 ' + getActiveClass(data)
                              }
                              onClick={() => shrinkItems(data, index)}
                            >
                              <i className={'sidenav-link-icon ' + data.icon} />
                              <span className="sidenav-link-text">
                                {data.label}
                              </span>
                              {data.items && collapsed && (
                                <i
                                  className={
                                    'menu-collapse-icon  ' +
                                    `${!data.expanded ? 'fal fa-angle-right' : 'fal fa-angle-down'}`
                                  }
                                />
                              )}
                            </a>
                          )}

                          {(!data.items || (data.items && data.items.length === 0)) && (
                            <Link
                              className={' sidenav-nav-link ' + getActiveClass(data)}
                              to={data.routeLink}
                              onClick={() => shrinkItems(data, index)}
                            >
                              <i className={'sidenav-link-icon ' + data.icon} />
                              <span className="sidenav-link-text">{data.label}</span>
                            </Link>
                          )}
                          {collapsed && data.expanded && data.items && data.items.length && (
                            <div>
                              <ul className="sublevel-nav">
                                {data.items.map((data, navIndex) => {
                                  return (
                                    <li className="sublevel-nav-item">
                                      <div
                                        className="sublevel-nav-link"
                                        onClick={() => {
                                          navClick(data, navIndex);
                                        }}
                                      >
                                        <span className="sublevel-link-text">{data.label}</span>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                          {!collapsed && data.expanded && data.items && data.items.length && (
                            <div className="sidebar-hover-panel">
                              <ul className="sublevel-nav">
                                {data.items.map((data, navIndex) => {
                                  return (
                                    <li className="sublevel-nav-item">
                                      <div
                                        className=" sublevel-nav-link"
                                        onClick={() => {
                                          navClick(data, navIndex);
                                        }}
                                      >
                                        <span className="sublevel-link-text ">
                                          {data.label}
                                        </span>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="sidebar-user">
                  <ul className=" sidenav-user-nav">
                    <li >
                      <a href="#self"  >
                        <i className="sidenav-link-icon fa-question fal" />
                        <span className="sidenav-link-text ">Help</span>
                      </a>
                    </li>
                  </ul>
                </div>
                {!isTabView && <div className="sidebar-handle" onClick={() => toggleCollapse()} />}
              </SideBarWrapper>
            )} */}
            <PageContainer
              theme={themes}
              className={'page-container ' + `${collapsed ? 'body-trimmed' : ''} `}
            >
              {location.pathname.includes('settings') || location.pathname.includes('details') ? (
                <SecondarySidebar children={children} />
              ) : (
                <div className='main-content-div'>{children}</div>
              )}
            </PageContainer>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </MainLayoutWrapper>
  );
};
