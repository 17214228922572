// src/utils/emojiSearch.ts
import data from '@emoji-mart/data';
import { init, SearchIndex } from 'emoji-mart';

// Initialize the emoji-mart data once
init({ data });

// Define the type for emoji search results
interface Emoji {
    skins: {
        native: string;
    }[];
}

// Create a reusable search function
export const searchEmojis = async (value: string): Promise<string> => {
    // Perform the search and return the native emoji characters
    const emojis: Emoji[] = await SearchIndex.search(value);
    //   return emojis.map((emoji) => emoji.skins[0].native);
    return emojis[0]?.skins[0].native;
};
