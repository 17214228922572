import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { checkTokenExpiry } from '@src/utils/common';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { AppState } from '@src/app/rootReducer';

declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
  }
}

export const AppRoutes = () => {
  const { loggedIn } = useSelector((state: AppState) => state.auth);
  const routes = loggedIn && checkTokenExpiry() ? protectedRoutes : publicRoutes;

  const location = useLocation();
  const element = useRoutes([...routes]);

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    ReactGA.send({ hitType: 'pageview', page: pagePath, title: document.title });
  }, [location, document.title]);

  return <>{element}</>;
};
