import * as PhosphorIcons from '@phosphor-icons/react';
import { AppState } from '@src/app/rootReducer';
import { Theme } from '@src/styles/themes/themeInterface';
import { removeTrailingColon } from '@src/utils/common';
import { searchEmojis } from '@src/utils/emojiSearch';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LocalIcons } from './icons';
import styled from 'styled-components';

type ColorVariant =
  | 'content-primary'
  | 'content-secondary'
  | 'content-static'
  | 'content-success'
  | 'content-error'
  | 'content-tertiary'
  | 'content-accentIcon' 
  | 'currentColor';

//TODO: background
// Define the props interface for the FltIcon component
interface IconProps {
  name: string; // Name of the icon
  weight?: 'thin' | 'light' | 'regular' | 'bold' | 'fill' | 'duotone'; // Weight of the icon (default: 'regular')
  size?: number; // Size of the icon (default: 24)
  color?: string;
  iconColor?: ColorVariant; // Color of the icon (default: 'currentColor')
  bg?:Boolean;
  source?: 'phosphor-icons' | 'local' | 'emoji'; // Source of the icon (default: 'phosphor-icons')
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

// FltIcon component definition
const FltIcon: React.FC<IconProps> = memo(
  ({
    name = 'AirPlane',
    weight = 'regular',
    size = 24,
    onClick,
    color,
    iconColor = 'currentColor',
    source = 'phosphor-icons',
  }) => {
    const theme = useSelector((data: AppState) => data.theme.theme);
    const [Icon, setIcon] = useState<React.FC<any> | null>(null);
    const [emoji, setEmoji] = useState<string | null>(null);

    useEffect(() => {
      const fetchEmoji = async () => {
        if (name && name.endsWith(':')) {
          const emojiResult = await searchEmojis(removeTrailingColon(name));
          setEmoji(emojiResult);
        } else {
          setEmoji(null);
        }
      };

      fetchEmoji();
    }, [name]);

    // Helper function to get text color from theme based on the textColor prop
    const getColor = (iconColor: ColorVariant, theme: Theme) => {
      const colorKeys = iconColor.split('-');
      if (iconColor === 'content-accentIcon') {
        return theme.content.accentIcon || iconColor;
      }
      const colorKey = colorKeys[1];
      return theme.content[colorKey as keyof typeof theme.content] || iconColor;
    };

    useEffect(() => {
      if (source === 'phosphor-icons') {
        const IconComponent = PhosphorIcons[name as keyof typeof PhosphorIcons] as React.FC<any>;

        if (IconComponent && typeof IconComponent === 'object') {
          setIcon(() => IconComponent);
        } else {
          console.error(`Icon ${name} not found in PhosphorIcons`);
        }
      }
    }, [name, source]);

    // Handle local icons
    if (source === 'local') {
      const LocalIcon = LocalIcons[name];
      if (LocalIcon && typeof LocalIcon === 'function') {
        return <LocalIcon size={size} color={color} onClick={onClick} />;
      } else {
        console.error(`Local icon ${name} is not a valid React component`);
        return <span>Local icon not found</span>;
      }
    }

    // Render emoji if it exists
    if (emoji) {
      return <span style={{ fontSize: size }}>{emoji}</span>;
    }

    // Return a default icon or placeholder if the icon hasn't been loaded yet
    if (!Icon) {
      return <PhosphorIcons.ImageBroken size={size} color={theme.content.primary} />;
    }

    // Render the dynamically imported Phosphor icon component
    return (
      <>
        <Icon
          weight={weight}
          size={size}
          color={getColor(iconColor, theme) || color}
          onClick={onClick}
        />
      </>
    );
  }
);

export default FltIcon;
