import Dexie from 'dexie';
import { io } from 'socket.io-client';

(function () {
  // Constants:
  var RECONNECT_DELAY = 5000; // Reconnect delay in case of errors such as network down.

  Dexie.Syncable.registerSyncProtocol('websocket', {
    sync: function (
      context,
      url,
      options,
      baseRevision,
      syncedRevision,
      changes,
      partial,
      applyRemoteChanges,
      onChangesAccepted,
      onSuccess,
      onError
    ) {
      // The following vars are needed because we must know which callback to ack when server sends it's ack to us.
      var requestId = 0;
      var acceptCallbacks = {};

      // Connect the Socket to given url:
      console.log(url);
      // const socket = io(url, {transports: ["websocket"], protocols: { retries: 3 }});
      const socket = io(url, { retries: 0, transports: ['websocket', 'polling'] });
      console.log('New dexie socket sync initialization');

      function closeSocketConnection() {
        socket.disconnect();
        console.log(new Date(), socket.id, 'Socket disconnected');
      }

      socket.on('connect_error', (error) => {
        console.log(new Date(), 'Socket connect error', error);
      });

      // When Socket opens, send our changes to the server.
      socket.on('connect', () => {
        console.log(new Date(), 'socket.connected', socket.connected);

        const engine = socket.io.engine;
        engine.on('close', (reason) => {
          closeSocketConnection();

          const reconnectDelay = reason == 'transport close' ? 1 : RECONNECT_DELAY;
          onError('Socket closed: ' + reason, reconnectDelay);
          console.log('socket engine closed', reason);
          // called when the underlying connection is closed
        });
      });

      socket.on('connected', () => {
        console.log('socket connected', socket.id, context.clientIdentity);
        socket.emit('clientIdentity', { clientIdentity: context.clientIdentity || null });
        socket.emit('subscribe', { syncedRevision: syncedRevision });
      });

      // isFirstRound: Will need to call onSuccess() only when we are in sync the first time.
      // onSuccess() will unblock Dexie to be used by application code.
      // If for example app code writes: db.friends.where('shoeSize').above(40).toArray(callback), the execution of that query
      // will not run until we have called onSuccess(). This is because we want application code to get results that are as
      // accurate as possible. Specifically when connected the first time and the entire DB is being synced down to the browser,
      // it is important that queries starts running first when db is in sync.
      var isFirstRound = true;
      // When message arrive from the server, deal with the message accordingly:

      socket.on('changes', (data) => {
        try {
          console.log(
            `New changes from server length: ${data.changes.length}, currentRevision: ${data.currentRevision}, partial: ${data.partial}`
          );
      
          // console.log(data.changes);
          let tableArray = data.changes; // Create an object to store unique table names along with their ids
          const tableData = {};
      
          tableArray.forEach(({ table, obj, mods }) => {
            // Check if the table name is already in the tableData object
            if (tableData[table]) {
              // If yes, add the key to the existing array of ids
              if (obj) {
                tableData[table].push(obj?.id);
              } else {
                tableData[table].push(mods?.id);
              }
            } else {
              // If no, create a new array with the current key
              if (obj) {
                tableData[table] = [obj?.id];
              } else {
                tableData[table] = [mods?.id];
              }
            }
          });
      
          // Print the distinct table names with all their ids
          for (const tableName in tableData) {
            console.log(
              ` ${tableName}:[${tableData[tableName].join(', ')}] --> length: ${
                tableData[tableName].length
              }`
            );
          }
      
          applyRemoteChanges(data.changes, data.currentRevision, data.partial);
      
          if (isFirstRound && !data.partial) {
            // Since this is the first sync round and server sais we've got all changes - now is the time to call onsuccess()
            onSuccess({
              // Specify a react function that will react on additional client changes
              react: function (changes, baseRevision, partial, onChangesAccepted) {
                sendChanges(changes, baseRevision, partial, onChangesAccepted);
              },
              // Specify a disconnect function that will close our socket so that we dont continue to monitor changes.
              disconnect: function () {
                closeSocketConnection();
              },
            });
            isFirstRound = false;
          }
      
          // Set SyncStatus to Synced after successful synchronization
          localStorage.setItem('SyncStatus', 'Synced');
        } catch (error) {
          console.log('changes error', error.message);
          closeSocketConnection();
          onError(error, Infinity); // Something went crazy. Server sends invalid format or our code is buggy. Dont reconnect - it would continue failing.
      
          // Set SyncStatus to Not Synced in case of an error
          localStorage.setItem('SyncStatus', 'Not Synced');
        }
      });
      
      socket.on('clientIdentity', (data) => {
        try {
          context.clientIdentity = data.clientIdentity;
          console.log(`On clientIdentity ${context.clientIdentity}`);
          context.save();
        } catch (error) {
          console.log('clientIdentity error', error.message);
          closeSocketConnection();
          onError(error, Infinity); // Something went crazy. Server sends invalid format or our code is buggy. Dont reconnect - it would continue failing.
        }
      }); //FIXME

      socket.on('error', (data) => {
        try {
          console.log(`Socket error ${data.message}`);

          closeSocketConnection();
          onError(data.message, Infinity); // Don't reconnect - an error in application level means we have done something wrong.
        } catch (error) {
          console.log(`Socket error catch ${error.message}`);

          closeSocketConnection();
          onError(error, Infinity); // Something went crazy. Server sends invalid format or our code is buggy. Dont reconnect - it would continue failing.
        }
      });
    },
  });
})();
