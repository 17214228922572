import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Periods } from '@src/dbSyncManager/tables/period.entity';

interface PeriodsState {
  data: any[];
}

const initialState: PeriodsState = {
  data: [],
};

const periodsSlice = createSlice({
  name: 'periods',
  initialState,
  reducers: {
    setPeriods(state, action: PayloadAction<Periods[]>) {
      state.data = action.payload;
    },
  },
});

export const { setPeriods } = periodsSlice.actions;
export default periodsSlice.reducer;
