type WebVitalsMetrics = {
  name: string;
  delta: number;
  value: number;
  entries: PerformanceEntry[];
};

const reportWebVitals = (onPerfEntry?: (metrics: WebVitalsMetrics) => void) => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    import('web-vitals').then((webVitals) => {
      webVitals.onCLS(onPerfEntry);
      webVitals.onFID(onPerfEntry);
      webVitals.onFCP(onPerfEntry);
      webVitals.onLCP(onPerfEntry);
      webVitals.onTTFB(onPerfEntry);
    }).catch((err) => {
      console.error('Error importing web-vitals:', err);
    });
  }
};

export default reportWebVitals;