export const typography = {
  'display-extra-small': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: 'normal',
    // letterSpacing: '-0.64px',
    letterSpacing: '-0.28px',
  },
  'display-small': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 'normal',
    // letterSpacing: '-0.64px',
    letterSpacing: '-0.28px',
  },
  'display-medium': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: 'normal',
    // letterSpacing: '-0.64px',
    letterSpacing: '-0.28px',
  },
  'display-large': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 'normal',
    // letterSpacing: '-0.64px',
    letterSpacing: '-0.28px',
  },
  'heading-large': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 'normal',
    // letterSpacing: '-0.28px',
    letterSpacing: '-0.28px',
  },
  'heading-medium': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: 'normal',
    // letterSpacing: '-0.28px',
    letterSpacing: '-0.28px',
  },
  'heading-small': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    // letterSpacing: '-0.28px',
    letterSpacing: '-0.28px',
  },
  'body-small': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '140%',
    // letterSpacing: '-0.36px',
    letterSpacing: '-0.28px',
  },
  'body-medium': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '140%',
    // letterSpacing: '-0.36px',
    letterSpacing: '-0.28px',
  },
  'body-large': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '140%',
    // letterSpacing: '-0.36px',
    letterSpacing: '-0.28px',
  },
  'title-small': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 'normal',
    // letterSpacing: '-0.24px',
    letterSpacing: '-0.28px',
  },
  'title-medium': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: 'normal',
    // letterSpacing: '-0.24px',
    letterSpacing: '-0.28px',
  },
  'title-large': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    // letterSpacing: '-0.24px',
    letterSpacing: '-0.28px',
  },
  'label-ultra-small': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '120%',
    // letterSpacing: '-0.24px',
    letterSpacing: '-0.28px',
  },
  'label-small': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '120%',
    // letterSpacing: '-0.24px',
    letterSpacing: '-0.28px',
  },
  'label-medium': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '120%',
    // letterSpacing: '-0.24px',
    letterSpacing: '-0.28px',
  },
  'label-large': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '120%',
    // letterSpacing: '-0.24px',
    letterSpacing: '-0.28px',
  },
};
