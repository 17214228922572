import Brightness2OutlinedIcon from '@mui/icons-material/Brightness2Outlined';
import SettingsIcon from '@mui/icons-material/Settings';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import Menu from '@mui/material/Menu';
import { AppState } from '@src/app/rootReducer';
import FltButton from '@src/components/base/buttons/fltButton/fltButton';
import CustomAvatar from '@src/components/composite/avatar/avatar';
import { db } from '@src/dbSyncManager/init';
import { logOut } from '@src/features/auth/authSlice';
import { toggleColorMode } from '@src/features/theme/themeSlice';
import { getDisplayName } from '@src/utils/common';
import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProfileMenuWrapper } from './profileMenuStyle';
type AuthType = {
  auth: {
    userId: number;
  };
};
export const ProfileMenu: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let theme = useSelector((state: AppState) => state.theme.theme);

  let userId = useSelector((state: AppState) => state.auth.userId);
  const user = useLiveQuery(async () => {
    let userList = await db.users.toArray();
    const user = userList.find(
      (data: object) => (data as { id: string | undefined }).id === userId
    );
    return user;
  });
  const handleToggleColorMode = () => {
    dispatch(toggleColorMode());
  };
  useEffect(() => {
    if (theme.themeType === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [theme.themeType]);

  const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleLogout = () => {
    dispatch(logOut());
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const displayName = getDisplayName(
    useSelector((state: AppState) => state?.displayName?.displayName)
  );
  const handleMenuButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <span onClick={handleMenuButtonClick}>
        <CustomAvatar
          avatarColor={user?.avatarColor}
          avatarInitials={user?.avatarInitials}
          preferredName={user?.preferredName}
          firstName={user?.firstName}
          lastName={user?.lastName}
          // width="30px"
          // height="30px"
          // fontSize="12px"
          width="24px"
          height="24px"
          fontSize="10px"
        />
      </span>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={handleMenuClick}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: `drop-shadow(0px 2px 8px ${theme.pages.dropShadow})`,
            mt: 1.5,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 10,
              width: 10,
              height: 10,
              bgcolor: theme.fltMenu.background.primary,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <ProfileMenuWrapper theme={theme}>
          <div className="user-info-panel">
            <div className="user-info-profile">
              <div className="user-pic bottom-spacer ">
                <CustomAvatar
                  avatarColor={user?.avatarColor}
                  avatarInitials={user?.avatarInitials}
                  width="45px"
                  height="45px"
                  fontSize="18px"
                />
              </div>
              <p className="user-name p1 ">{displayName}</p>
              <p className="email-div">
                {user && user.publicCredential ? user.publicCredential : ''}
              </p>

              <div className="action-btn-div ">
                <FltButton
                  shape="circle"
                  appearance="outline"
                  type="secondary"
                  onClick={() => navigate('/settings/my-account/preferences')}
                  leftGlyph={<SettingsIcon style={{ fontSize: '24px' }} />}
                  size="large"
                />
                <FltButton
                  shape="circle"
                  appearance="outline"
                  type="secondary"
                  onClick={() => handleToggleColorMode()}
                  size="large"
                  leftGlyph={
                    theme.themeType === 'dark' ? (
                      <WbSunnyOutlinedIcon
                        className="sidenav-link-icon"
                        style={{
                          fontSize: '24px',
                        }}
                      />
                    ) : (
                      <Brightness2OutlinedIcon
                        style={{
                          fontSize: '24px',
                        }}
                      />
                    )
                  }
                />
              </div>
            </div>
            <div className="content-divider" />
            <div className="user-accounts-controls">
              <div className="user-info-panel-unit ">
                <FltButton
                  type="secondary"
                  appearance="outline"
                  size="large"
                  onClick={() => handleLogout()}
                  action="button"
                >
                  <i className="far fa-sign-out" />
                  <span className="logout-div">Logout</span>
                </FltButton>
              </div>
              <p className="p3 clear-margin privacy-div">
                <a href="">Privacy Policy</a>&nbsp; | &nbsp;
                <a href="">Terms of Service</a>
              </p>
            </div>
          </div>
        </ProfileMenuWrapper>
      </Menu>
    </div>
  );
};
