import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Groups } from '@src/dbSyncManager/tables/groups.entity';

interface GroupsState {
  data: any[];
}

const initialState: GroupsState = {
  data: [],
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups(state, action: PayloadAction<Groups[]>) {
      state.data = action.payload;
    },
  },
});

export const { setGroups } = groupsSlice.actions;
export default groupsSlice.reducer;
