import { INavbarData } from '../mainLayoutInterface';

export const navbarData: INavbarData[] = [
  {
    routeLink: 'dashboard',
    icon: 'fal fa-home',
    label: 'Dashboard',
  },
  {
    routeLink: 'pay',
    icon: 'fal fa-credit-card',
    label: 'Pay',
    items: [
      {
        routeLink: 'pay/bills',
        label: 'Bills',
      },
      {
        routeLink: 'pay/vendors',
        label: 'Vendors',
      },
      {
        routeLink: 'pay/products-and-services',
        label: 'Products & Services',
      },
    ],
  },
  {
    routeLink: 'get-paid',
    icon: 'fal fa-university',
    label: 'Get Paid',
    items: [
      {
        routeLink: 'get-paid/estimates',
        label: 'Estimates',
      },
      {
        routeLink: 'get-paid/invoices',
        label: 'Invoices',
      },
      {
        routeLink: 'get-paid/recurring-invoices',
        label: 'Recurring Invoices',
      },
      {
        routeLink: 'get-paid/customer-statements',
        label: 'Customer Statements',
      },
      {
        routeLink: 'get-paid/customers',
        label: 'Customers',
      },
      {
        routeLink: 'get-paid/products-and-services',
        label: 'Products & Services',
      },
      {
        routeLink: 'get-paid/tax-rates',
        label: 'Tax Rates',
      },
      {
        routeLink: 'get-paid/shipping-rates',
        label: 'Shipping Rates',
      },
      {
        routeLink: 'get-paid/discounts',
        label: 'Discounts',
      },
    ],
  },
  {
    routeLink: 'teams',
    icon: 'fa fa-users',
    label: 'Groups',
    items: [
      {
        routeLink: 'groups/teams',
        label: 'Teams',
      },
      {
        routeLink: 'groups/initiatives',
        label: 'Initiatives',
      },
      {
        routeLink: 'groups/programs',
        label: 'Programs',
      },
      {
        routeLink: 'groups/views',
        label: 'Views',
      },
    ],
  },
  {
    routeLink: 'payroll',
    icon: 'fal fa-calculator',
    label: 'Payroll',
    items: [
      {
        routeLink: 'payroll/setup',
        label: 'Setup',
      },
      {
        routeLink: 'payroll/employees',
        label: 'Employees',
      },
      {
        routeLink: 'payroll/timeoff',
        label: 'Timeoff',
      },
      {
        routeLink: 'payroll/timesheets',
        label: 'Timesheets',
      },
      {
        routeLink: 'payroll/transactions',
        label: 'Transactions',
      },
    ],
  },
  {
    routeLink: 'accounting',
    icon: 'fal fa-book',
    label: 'Accounting',
    items: [
      {
        routeLink: 'accounting/transactions',
        label: 'Transactions',
      },
      {
        routeLink: 'accounting/reconciliation',
        label: 'Reconciliation',
      },
      {
        routeLink: 'accounting/connected-accounts',
        label: 'Connected Accounts',
      },
      {
        routeLink: 'accounting/chart-of-accounts',
        label: 'Chart of Accounts',
      },
    ],
  },
  {
    routeLink: 'reports',
    icon: 'fal fa-file',
    label: 'Reports',
    items: [
      {
        routeLink: 'reports/financial-statements',
        label: 'Financial Statements',
      },
      {
        routeLink: 'reports/taxes',
        label: 'Taxes',
      },
      {
        routeLink: 'reports/customers',
        label: 'Customers',
      },
      {
        routeLink: 'reports/vendors',
        label: 'Vendors',
      },
      {
        routeLink: 'reports/payroll',
        label: 'Payroll',
      },
      {
        routeLink: 'reports/other-reports',
        label: 'Other Reports',
      },
    ],
  },
];

export const settingNavBarData: INavbarData[] = [
  {
    routeLink: '',
    icon: 'fa fa-solid fa-user',
    label: 'My account',
    baseRoute: '/settings/my-account/preferences',
    //expanded: true,
    items: [
      {
        routeLink: '/settings/my-account/profile',
        label: 'Profile',
        icon: 'ProfileTick',
      },
      {
        routeLink: '/settings/my-account/contact-information',
        label: 'Contact Information',
        icon: 'CallOutgoing',
      },
      {
        routeLink: '/settings/my-account/preferences',
        label: 'Preferences',
        icon: 'Edit2',
      },
      {
        routeLink: '/settings/my-account/notifications',
        label: 'Notifications',
        icon: 'NotificationStatus',
      },
      {
        routeLink: '/settings/my-account/security-and-access',
        label: 'Security and Access',
        icon: 'SecuritySafe',
      },
    ],
  },
  {
    routeLink: '',
    icon: 'fa fa-building',
    label: 'Company workspace',
    //expanded: true,
    items: [
      {
        routeLink: '/settings/company-workspace/profile',
        label: 'Profile',
        icon: 'Briefcase',
      },
      {
        routeLink: '/settings/company-workspace/user-management',
        label: 'User Management',
        icon: 'UserEdit',
      },
      {
        routeLink: '/settings/company-workspace/danger-zone',
        label: 'Danger Zone',
        icon: 'Danger',
      },
    ],
  },
];

export const getTeamsDetailsNavBarData = (id: any) => {
  return [
    {
      routeLink: `/details/${id}/general`,
      icon: 'fa fa-solid fa-user',
      label: 'Team Details',
      baseRoute: '/groups/teams',
      items: [
        {
          routeLink: `/groups/teams/details/${id}/general`,
          label: 'General',
          icon: 'Users',
        },
        {
          routeLink: `/groups/teams/details/${id}/hierarchy-views`,
          label: 'Hierarchy Views',
          icon: 'TreeView',
        },
        {
          routeLink: `/groups/teams/details/${id}/members`,
          label: 'Members',
          icon: 'UsersFour',
        },
        {
          routeLink: `/groups/teams/details/${id}/initiatives`,
          label: 'Initiatives',
          icon: 'Magicpen',
        },
        // {
        //   routeLink: `/groups/teams/details/${id}/positions`,
        //   label: 'Positions',
        //   icon: 'MoneyAdd',
        // },
        
      ],
    },
  ];
};
