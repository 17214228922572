import { AppState } from '@src/app/rootReducer';
import { useSelector } from 'react-redux';
import FltIcon from '../../fltIcon/fltIcon';
import { FltButtonDropdownProps, FltButtonProps } from './fltButtonInterface';
import { FltButtonWrapper } from './fltButtonStyles';
const FltButtonNew = ({
  size = 'default',
  rightGlyph,
  leftGlyph,
  type,
  isLoading,
  action,
  disabled,
  children,
  onClick,
  appearance = 'filled',
  shape,
  childLayout = 'default',
  btnFor = 'normal-btn',
  ...restProps
}: FltButtonProps) => {
  const theme = useSelector((data: AppState) => data.theme.theme);
  // Determine the additional className based on isDropdownOpen
  const dropdownClass =
    btnFor === 'dropdown-btn' && (restProps as FltButtonDropdownProps).isDropdownOpen
      ? 'dropdown-open'
      : '';
  const iconName =
    btnFor === 'dropdown-btn' && (restProps as FltButtonDropdownProps).isDropdownOpen
      ? 'CaretUp'
      : 'CaretDown';
  return (
    <FltButtonWrapper
      type={action}
      size={size}
      btnType={type}
      appearance={appearance}
      onClick={onClick}
      disabled={disabled}
      theme={theme}
      shape={shape}
      childLayout={childLayout}
      className={dropdownClass}
      btnFor={btnFor}
      {...restProps}
      
    >
      {(leftGlyph || children || rightGlyph || isLoading) && (
        <div className="btn-content">
          {leftGlyph}
          {children && children}
          {btnFor == 'dropdown-btn' ? (
            <FltIcon weight="thin" name={iconName} size={18}></FltIcon>
          ) : (
            rightGlyph
          )}
          {isLoading && <>loading...</>}
        </div>
      )}
    </FltButtonWrapper>
  );
};

export default FltButtonNew;
