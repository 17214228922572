import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

type MainLayoutWrapperProps = {
  theme: Theme;
  collapsed: boolean;
};
//main layout
export const MainLayoutWrapper = styled.div<MainLayoutWrapperProps>`
  .page-container {
    width: ${({ collapsed }) => (collapsed ? 'calc(100% )' : 'calc(100% - 220px)')}!important;
    bottom: 0;
    /* margin-left: 82px; */
    margin-left: ${({ collapsed }) => (collapsed ? '10px' : '220px')};
   // transition: all 0.05s ease;
    display: flex;
    flex-direction: column;
  }
  .pageContainer-bg {
    min-width: 100%;
  }
  .body-trimmed {
    width: calc(100% - 180px) !important;
    margin-left: 178px;
  }

  .sidenav {
   // transition: all 0.5s ease;
    position: fixed;
    z-index: 101;
    top: 0;
    width: 82px;
    height: 100vh;
    border-right: 1px solid ${({ theme }) => theme.pages.layout.sidebar.border.primary};

    .sidebar-handle {
      position: absolute;
      left: 100%;
      top: 120px;
      bottom: 120px;
      padding: 0 8px 0 8px;
      cursor: pointer;
      z-index: 5;
      &:after,
      &:before {
        content: '';
        width: 20px;
        height: 3px;
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
        margin: 0 auto;
       // transition: 0.2s;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        border-radius: 2px;
      }
      &:before {
        top: calc(50% - 17px);
      }
    }
    &.sidenav-collapsed {
      .sidebar-handle {
        &:hover {
          &:before {
            transform: rotate(114deg);
            /* background-color: ${({ theme }) => theme.pages.layout.sidebar.background}; */
          }
        }
        &:hover {
          &:after {
            transform: rotate(66deg);
            /* background-color: ${({ theme }) => theme.pages.layout.sidebar.background}; */
          }
        }
      }
    }
  }
  .sidesubnav-hover {
    .logo-container {
      button.logo {
        margin: 0 auto;
      }
      span {
        font-size: 25px;
        color: ${({ theme }) => theme.pages.layout.sidebar.text.primary};
      }
    }
  }
  .sidenav-collapsed {
    width: 180px;

    .sidenav-nav {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      .sidenav-nav-item {
        .sidenav-nav-link {
          flex-direction: row;
          .sidenav-link-text {
            margin: 0 0.25rem 0 0.25rem;
            color: ${({ theme }) => theme.color.white};
          }
        }
      }
    }
  }

  .sidenav-nav {
    list-style: none;
    padding: 1rem 0.4rem 0 0.4rem;
    margin: 0;
    font-size: 12px;

    .sidenav-nav-item {
      width: 100%;
      margin-bottom: 0.625rem;
      position: relative;

      .sidenav-nav-hover-link:hover {
        background-color: ${({ theme }) => theme.color.white};
      }

      .sidenav-nav-link {
        color: ${({ theme }) => theme.color.white};
        text-decoration: none;
        border-radius: 0.625rem;
      //  transition: all 0.3s ease;
        padding: 0.5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .sidenav-link-icon {
          font-size: 18px;
          width: 2rem;
          min-width: 2rem;
          margin: 0 0.3rem;
          text-align: center;
          color: ${({ theme }) => theme.color.white};
        }

        .sidenav-link-text {
          margin: 0.3rem 0 0 0;
          color: ${({ theme }) => theme.color.white};
        }
      }

      .sidenav-nav-link:hover {
        background-color: ${({ theme }) => theme.pages.layout.sidebar.background.hover.primary};
        color: ${({ theme }) => theme.color.white};

        .menu-collapse-icon {
          color: ${({ theme }) => theme.color.white};
          text-align: center;
        }
      }
    }
  }

  .active {
    background-color: ${({ theme }) => theme.pages.layout.sidebar.background.hover.primary};

    .menu-collapse-icon,
    .sidenav-link-icon,
    .sidenav-link-text {
      color: ${({ theme }) => theme.color.white};
    }
  }

  .active-sublevel {
    color: ${({ theme }) => theme.color.white};
  }

  .menu-collapse-icon {
    font-size: 25px;
    width: 2rem;
    min-width: 2rem;
    margin: auto 0.5rem auto auto;
  }

  .sublevel-nav {
    list-style: none;
    margin: 0;
    padding: 0 0;

    .sublevel-nav-item {
      .sublevel-nav-link {
        height: 2rem;
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.color.white} !important;
        text-decoration: none;
        border-radius: 0.625rem;
       // transition: all 0.3s ease;
        display: flex;
        align-items: center;

        &:hover {
          background: ${({ theme }) => theme.pages.layout.sidebar.background.hover.primary};
        }

        .sublevel-link-text {
          margin-left: 0.8rem;
          color: ${({ theme }) => theme.color.white};
        }
      }

      .sublevel-nav-link:hover {
        color: ${({ theme }) => theme.color.white};
      }
    }
  }
  //user & help menu
  .sidebar-user {
    .sidenav-user-nav {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      align-items: center;
      list-style: none;
      padding: 0 0.4rem 1rem 0.4rem;
      margin: 0;
      font-size: 12px;
      li {
        position: relative;

        width: 1;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-height: 3rem;
          color: ${({ theme }) => theme.color.white};
          text-decoration: none;
          border-radius: 0.625rem;
         // transition: all 0.3s ease;
          padding: 0.5rem 0;
          &:hover {
            background-color: ${({ theme }) => theme.pages.layout.sidebar.background.hover.primary};
            color: ${({ theme }) => theme.color.white};
          }
          i {
            font-size: 18px;
            width: 2rem;
            min-width: 2rem;
            margin: 0 0.3rem;
            text-align: center;
          }
          svg {
            font-size: 18px;
            width: 2rem;
            min-width: 2rem;
            margin: 0 0.3rem;
            text-align: center;
          }
          span {
            margin: 0 0.25rem 0 0.25rem;
            color: ${({ theme }) => theme.color.white} !important;
          }
        }
      }
    }
  }
  .sidenav-collapsed {
    .sidebar-user {
      .sidenav-user-nav {
        li a {
          flex-direction: row;
          color: ${({ theme }) => theme.color.white} !important;
        }
      }
    }
  }
  // side bar hover
  .sidebar-hover-panel {
    background: ${({ theme }) => theme.pages.layout.sidebar.background.primary};
    position: absolute;
    left: 80px;
    top: 0;
    min-width: 200px;
    border-radius: 10px;
    z-index: 6;
  }

  //scrollbar and visibility on hover and focus
  .sidesubnav-hover {
    .scrollwrap {
      overflow: visible !important;
    }
  }

  .scrollwrap {
    overflow-y: auto;
    visibility: hidden;
    //height: calc(100% - 3.65rem);
    flex: 1 1 auto;
    .sidenav-nav {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      align-items: center;
    }
  }

  .sidenav-nav,
  .scrollwrap:hover,
  .scrollwrap:focus {
    visibility: visible;
  }

  .scrollwrap_delayed {
  //  transition: visibility 0.5s 0.2s;
  }

  .scrollwrap_delayed:hover {
   // transition: visibility 0.2s 0.2s;
  }

  // works on chrome, edge and safari
  /* .scrollwrap::-webkit-scrollbar {
    width: 10px;
  }

  .scrollwrap::-webkit-scrollbar-track {
    background: transparent;
  }

  .scrollwrap::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.pages.layout.sidebar.scrollBarColor};
    border-radius: 20px;
  } */

  // works on firefox
  /* .scrollwrap {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.pages.layout.sidebar.scrollBarColor} transparent;
  }

  .sidebar-bg {
    position: fixed;
    background: transparent;
    z-index: 1;
  } */

  /* @media only screen and (min-width: 577px) and (max-width: 1024px) {
    .sidebar-bg {
      display: none !important;
    }

    .scrollwrap {
      flex: none !important;
    }
    .sidenav-nav {
      padding-bottom: 0 !important;
    }
    .sidenav-user-nav {
      padding-top: 0 !important;
    }
  } */

  /* @media only screen and (orientation: landscape) and (max-height: 850px) {
    .sidebar-bg {
      display: none !important;
    }

    .scrollwrap {
      flex: none !important;
    }
    .sidenav-nav {
      padding-bottom: 0 !important;
    }
    .sidenav-user-nav {
      padding-top: 0 !important;
    }
  } */

  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => `#d9dde1 transparent`};
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    background: ${({ theme }) => `${theme.pages.scrollbar.track}`};
  }

  *::-webkit-scrollbar-thumb {
    /* background-color: ${({ theme }) => `${theme.pages.scrollbar.thumb}`}; */
    /* background-color: transparent !important; */
    border-radius: 20px;
    border: ${({ theme }) => `3px solid ${theme.pages.scrollbar.thumbBorder}`};
    opacity: 0.2;
  }
`;

export const SideBarWrapper2 = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.pages.layout.sidebar.background.primary};
  /* transition: all 0.5s ease; */
  position: fixed;
  z-index: 101;
  top: 0;
  width: 220px;
  height: 100vh;
  overflow-y: auto;
  /* border-right: 1px solid ${({ theme }) => theme.pages.layout.sidebar.border.primary}; */
  .sidebar-header {
    display: flex;
    width: 220px;
    height: 80px;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background-color: inherit;
    border-bottom: 1px solid ${({ theme }) => theme.pages.layout.sidebar.border.primary};
    border-right: 1px solid ${({ theme }) => theme.pages.layout.sidebar.border.primary};

    .left-container,
    .rigth-container {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  .create-btn-container {
    display: flex;
    padding: 0px 14px 8px 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    margin-top: 24px;
    /* width: 100%; */
  }

  .divider {
    /* height: 1px;
  background-color: #CFD6DB;
  margin: 16px 0px;
  filter: drop-shadow(0px 1px 0px #FFF); */
    height: 1px;
    width: 220px;
    background-color: #cfd6db;
    box-shadow: 0px 1px 0px #fff;
    margin: 0 auto; /* Center the divider */
    margin: 16px 0px;
  }
  .nav-item-group-title-container {
    display: flex;
    padding: 0px 28px 6px 28px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
`;

//styles for sidebar
export const SideBarWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 57px;
    .company-name-div {
      font-weight: 900;
      color: ${({ theme }) => theme.color.white};
    }
  }
  background: ${({ theme }) => theme.pages.layout.sidebar.background.primary};

  .sidebar-handle {
    &:after,
    &:before {
      background-color: ${({ theme }) => theme.color.black};
    }

    &:hover {
      &:before {
        transform: rotate(66deg);
        background-color: ${({ theme }) => theme.color.black};
      }
    }
    &:hover {
      &:after {
        transform: rotate(114deg);
        background-color: ${({ theme }) => theme.color.black};
      }
    }
  }
  &.sidenav-collapsed {
    .sidebar-handle {
      &:hover {
        &:before {
          transform: rotate(114deg);
          background-color: ${({ theme }) => theme.color.black};
        }
      }
      &:hover {
        &:after {
          transform: rotate(66deg);
          background-color: ${({ theme }) => theme.color.black};
        }
      }
    }
  }

  @media only screen and (orientation: landscape) and (max-height: 300px) {
    max-height: 100vh !important;
    overflow-y: scroll !important;
  }
`;

//styles for the page container -child
export const PageContainer = styled.div<{ theme: Theme }>`
  background: ${({ theme }) => theme.pages.background.primary};
  height: 100vh;
  position: fixed !important;
  overflow: hidden !important;
  .main-content-div{
    overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 8px 12px 8px 0px;
  border-radius: 6px;
  border: ${({ theme }) =>`1px solid ${theme.pages.layout.secondarySidebar.border.primary}` };
  }
`;
