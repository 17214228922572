import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DisplayNameState = {
  displayName: string;
};

const initialState: DisplayNameState = {
  displayName: 'FirstName LastName (Preferred Name)',
};

const displayNameSlice = createSlice({
  name: 'displayName',
  initialState,
  reducers: {
    setDisplayName: (state, action: PayloadAction<string>) => {
      state.displayName = action.payload;
    },
  },
});

export const { setDisplayName } = displayNameSlice.actions;
export default displayNameSlice.reducer;
