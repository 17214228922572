import { Backdrop, CircularProgress, Theme } from '@mui/material';
import Logo from '@src/assets/images/icon_flute-temp.png';
import React from 'react';
import { useSelector } from 'react-redux';
import { LoaderWrapper } from './fltLoaderStyles';
import { FltLoaderProps } from './fltLoaderType';
import { AppState } from '@src/app/rootReducer';

export const FltLoader: React.FC<FltLoaderProps> = ({
  isLoading,
  loaderText,
  isLogoNeeded = true,
}: FltLoaderProps) => {
  const theme = useSelector((data: AppState) => data.theme.theme);
  return (
    <Backdrop
      sx={{
        color: theme.textPrimary,
        backgroundColor: theme.pages.background.primary,
        zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
      }}
      open={isLoading}
    >
      <LoaderWrapper theme={theme}>
        {isLogoNeeded && (
          <img
            src={Logo}
            className="text-center"
            title="flute"
            alt="flute"
            height="70px"
            width="70px"
          />
        )}
        {loaderText && <p className="loader-text">{loaderText}</p>}
        <CircularProgress color="success" />
      </LoaderWrapper>
    </Backdrop>
  );
};
