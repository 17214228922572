import Dexie, { Table } from 'dexie';
import 'dexie-observable';
import 'dexie-syncable';
import { SYNC_URL } from '../config';
import './socketSyncProtocol.js';

// Importing entity classes
import store from '@src/app/store';
import { setGroups } from '@src/features/entity/groups/groupsSlice';
import { setPrograms } from '@src/features/entity/initiatives/initiativeSlice';
import { setPeriods } from '@src/features/entity/periods/periodsSlice';
import { setPreference } from '@src/features/entity/preference/preferenceSlice';
import { setInitiatives } from '@src/features/entity/programs/programsSlice';
import { setTeams } from '@src/features/entity/teams/teamsSlice';
import { setUserRelship } from '@src/features/entity/userRelship/userRelshipSlice';
import { setUsers } from '@src/features/entity/users/userRelshipSlice';
import { Account } from './tables/account.entity';
import { Address } from './tables/address.entity.js';
import { Approvals } from './tables/approvals.entity';
import { Discount } from './tables/discount.entity';
import { Email } from './tables/email.entity.js';
import { Events } from './tables/events.entity';
import { EventTemplate } from './tables/eventTemplate.entity';
import { Groups } from './tables/groups.entity';
import { Invoice } from './tables/invoice.entity';
import { InvoiceLineItem } from './tables/invoiceLineItem.entity';
import { Ledger } from './tables/ledger.entity';
import { NotificationLogs } from './tables/notification_logs.entity.js';
import { Payment } from './tables/payment.entity.js';
import { Periods } from './tables/period.entity';
import { PhoneNumber } from './tables/phoneNumbers.entity.js';
import { Positions } from './tables/positions.entity';
import { Preference } from './tables/preference.entity';
import { ProcessedEventMessage } from './tables/processedEventMessage.entity';
import { Product } from './tables/product.entity.js';
import { PublishedEvent } from './tables/publishedEvents.entity';
import { ScheduledNotifications } from './tables/scheduled-notifications.entity';
import { Shipping } from './tables/shipping.entity';
import { SubLedger } from './tables/subLedger.entity.js';
import { SubLedgerEntry } from './tables/subLedgerEntry.entity';
import { Tax } from './tables/tax.entity.js';
import { User } from './tables/user.entity.js';
import { UserRelships } from './tables/userRelships.entity.js';

// Define the main AppDB class extending Dexie
export class AppDB extends Dexie {
  // Declare tables
  accounts!: Table<Account, number>;
  invoices!: Table<Invoice, number>;
  invoice_line_items!: Table<InvoiceLineItem, number>;
  scheduled_notifications!: Table<ScheduledNotifications, number>;
  notification_logs!: Table<NotificationLogs, number>;
  users!: Table<User, number>;
  payment!: Table<Payment, number>;
  addresses!: Table<Address, number>;
  products!: Table<Product, number>;
  preferences!: Table<Preference, number>;
  shipping!: Table<Shipping, number>;
  taxes!: Table<Tax, number>;
  ledgers!: Table<Ledger, number>;
  discount!: Table<Discount, number>;
  sub_ledger!: Table<SubLedger, number>;
  subledger_entry!: Table<SubLedgerEntry, number>;
  emails!: Table<Email, number>;
  phone_numbers!: Table<PhoneNumber, number>;
  user_relships!: Table<UserRelships, number>;
  groups!: Table<Groups, number>;
  positions!: Table<Positions, number>;
  periods!: Table<Periods, number>;
  approvals!: Table<Approvals, number>;
  processedEventMessages!: Table<ProcessedEventMessage, number>;
  events!: Table<Events, number>;
  published_events!: Table<PublishedEvent, number>;
  event_templates!: Table<EventTemplate, number>;

  constructor() {
    // Call Dexie constructor with database name
    super('flute');

    // Define version 17 schema
    this.version(17).stores({
      users: '$$oid, id',
      user_privileges: '$$oid, id',
      privileges: '$$oid, id',
      addresses: '$$oid, id',
      emails: '$$oid, id',
      phone_numbers: '$$oid, id',
      accounts: `$$oid, id`,
      invoices: `$$oid, id`,
      products: '$$oid, id',
      taxes: '$$oid, id',
      invoice_line_items: '$$oid, id',
      invoice_line_item_taxes: '$$oid, id',
      products_taxes_taxes: '$$oid',
      product_taxes: '$$oid',
      ledger: '$$oid',
      sub_ledger: '$$oid,id',
      sub_ledger_entry: '$$oid',
      payment: '$$oid, id, paymentBYId',
      notification_logs: '$$oid, [entityType+entityId]',
      scheduled_notifications: '$$oid, id, [entityType+entityId]',
      devices: '$$oid',
      preferences: '$$oid',
      discounts: '$$oid',
      shipping: '$$oid',
      payments: '$$oid',
      user_relships: '$$oid',
      groups: '$$oid,id',
      positions: '$$oid,id',
      periods: '$$oid,id',
      approvals: '$$oid,id',
      processed_event_messages: '$$oid, id',
      events: '$$oid,id',
      published_events: '$$oid,id',
      event_templates: '$$oid,id',
    });

    // Define version 2 schema (empty in this case)
    this.version(2).stores({});

    // Initialize synchronization with the server
    this.syncServer();

    this.loadInitialData();
    this.setupChangeListeners();
  }

  async loadInitialData() {
    const [groups, preferences, periods, user_relships, users] = await Promise.all([
      // this.accounts.toArray(),
      this.groups.toArray(),
      this.preferences.toArray(),
      this.periods.toArray(),
      this.user_relships.toArray(),
      this.users.toArray(),
    ]);

    const teams = groups.filter((group) => group.type === 'TEAM');
    const programs = groups.filter((group) => group.type === 'PROGRAM');
    const initiatives = groups.filter((group) => group.type === 'INITIATIVE');

    store.dispatch(setPreference(preferences));
    store.dispatch(setPeriods(periods));
    store.dispatch(setUserRelship(user_relships));
    store.dispatch(setGroups(groups));
    store.dispatch(setTeams(teams));
    store.dispatch(setPrograms(programs));
    store.dispatch(setInitiatives(initiatives));
    store.dispatch(setUsers(users));
  }

  setupChangeListeners() {
    this.on('changes', (changes) => {
      changes.forEach(async (change) => {
        switch (change.table) {
          case 'preferences':
            store.dispatch(setPreference(await this.preferences.toArray()));
            break;
          case 'periods':
            store.dispatch(setPeriods(await this.periods.toArray()));
            break;
          case 'groups':
            const groups = await this.groups.toArray();
            const teams = groups.filter((group) => group.type === 'TEAM');
            const programs = groups.filter((group) => group.type === 'PROGRAM');
            const initiatives = groups.filter((group) => group.type === 'INITIATIVE');
            store.dispatch(setGroups(groups));
            store.dispatch(setTeams(teams));
            store.dispatch(setPrograms(programs));
            store.dispatch(setInitiatives(initiatives));
            break;
          case 'user_relships':
            store.dispatch(setUserRelship(await this.user_relships.toArray()));
            break;
          case 'users':
            store.dispatch(setUsers(await this.users.toArray()));
            break;
        }
      });
    });
  }

  // Method to initialize synchronization with the server
  syncServer() {
    console.log('start');
    // Get the authentication user string from localStorage
    const authUserString = window.localStorage.getItem('appState');
    if (authUserString) {
      const accessToken = JSON.parse(authUserString || '').auth.accessToken;
      // Connect to sync server with access token
      this.syncable.connect('websocket', `${SYNC_URL}?token=${accessToken}`);
      // Disconnect previous connection if any
      this.syncable.disconnect('disconnected');
      // Listen for status changes
      this.syncable.on('statusChanged', function (newStatus) {
        console.log('Sync Status changed: ' + Dexie.Syncable.StatusTexts[newStatus]);
      });
    } else {
      console.log('Skip DB Sync');
    }
  }
}

// Log initialization
console.log('Init DB');

// Export an instance of the AppDB class
export const db = new AppDB();
