import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Groups } from '@src/dbSyncManager/tables/groups.entity';

interface GroupsState {
  data: any[];
}

const initialState: GroupsState = {
  data: [],
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams(state, action: PayloadAction<Groups[]>) {
      state.data = action.payload;
    },
  },
});

export const { setTeams } = teamsSlice.actions;
export default teamsSlice.reducer;
